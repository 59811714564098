import styled from 'styled-components';

export const LoginWrapper = styled.div`
    margin: 0;
    border: 0;
    box-sizing: border-box;
    background-color: #f5f5f5;

    position: relative;
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
