import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Button, HelperText, SelectList } from '@freenow/wave';

import { useGroupList } from '../../employeeGroups/hooks/useGroupList';
import { PremiumFeatureEnabled } from '../../premium/components/PremiumFeatureEnabled';
import { Group } from '../../employeeGroups/types';
import { useFormatMessage } from '../hooks/useFormatMessage';
import { InputTagsField } from './InputTagsField';

const MAX_CONCURRENT_INVITES = 250;

interface Key {
    controlRepresentation: string;
    names: string[];
}

interface Props {
    singleGroupSelection: boolean;
    cancelButtonClicked: () => void;
    confirmButtonClicked: (tags: string[], groups: Group[]) => void;
    separationKeys: Key[];
    inputValidationRegex: RegExp;
    cancelButtonText: string;
    confirmButtonText: string;
    placeholderText: string;
    submitButtonTestId: string;
    tagsFieldTestId: string;
}

export const PopupTagsInput = (props: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const formatMessage = useFormatMessage();
    const { data: availableGroups } = useGroupList();

    const onTagsChange = (tags) => {
        setTags(tags);
        setInputValue('');
    };

    const onInputChange = (inputValue) => {
        setInputValue(inputValue);
    };

    const isInputTextValid = () => {
        if (props.inputValidationRegex) {
            return !!inputValue.trim().match(props.inputValidationRegex);
        }
        return false;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (isInputTextValid() && inputValue.length) {
            props.confirmButtonClicked([...tags, inputValue], selectedGroups);
        } else {
            props.confirmButtonClicked(tags, selectedGroups);
        }
    };

    const hasTooManyInvites = tags.length > MAX_CONCURRENT_INVITES;
    const saveButtonEnabled = (tags.length >= 1 || isInputTextValid()) && !hasTooManyInvites;
    const availableOptions = (availableGroups || []).map((it) => ({
        label: it.name,
        value: it.id,
    }));

    const placeholder = !availableOptions.length
        ? formatMessage('employees.multipleEmployeesPlaceholder.noGroups')
        : formatMessage('employees.multipleEmployeesPlaceholder.selectGroups');

    return (
        <>
            <InputTagsField
                tags={tags}
                inputValue={inputValue}
                onInputChange={onInputChange}
                onTagsChange={onTagsChange}
                inputValidationRegex={props.inputValidationRegex}
                placeholderText={props.placeholderText}
                separationKeys={props.separationKeys}
                dataTestId={props.tagsFieldTestId}
            />
            <PremiumFeatureEnabled feature="travelPoliciesEnabled">
                <SelectList
                    mt={2}
                    isClearable
                    isMulti={!props.singleGroupSelection}
                    placeholder={placeholder}
                    isDisabled={!availableOptions.length}
                    value={selectedGroups}
                    onChange={(newGroups) =>
                        // @ts-ignore
                        setSelectedGroups(props.singleGroupSelection ? [newGroups] : newGroups || [])
                    }
                    options={availableOptions}
                    noOptionsMessage={() => formatMessage('employees.multipleEmployeesPlaceholder.noGroups')}
                />
            </PremiumFeatureEnabled>

            <Box display="inline-flex" justifyContent="center" mt={3}>
                {hasTooManyInvites ? (
                    <HelperText variant="danger" mb={2}>
                        <FormattedMessage id="employees.multipleEmployees.tooManyInvites" />
                    </HelperText>
                ) : null}

                <Button
                    data-testid={props.submitButtonTestId}
                    onClick={onSubmit}
                    disabled={!saveButtonEnabled}
                    type="submit"
                    mr={2}
                >
                    {props.confirmButtonText}
                </Button>
                <Button variant="secondary" onClick={props.cancelButtonClicked}>
                    {props.cancelButtonText}
                </Button>
            </Box>
        </>
    );
};
