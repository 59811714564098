import Logger from '@mytaxi/logging';
import { httpStatusCodes } from '../constants/httpStatusCodes';
import { AxiosError } from 'axios';

export const logAxiosError = (message: String, error: Error | AxiosError, containsSecrets = false) => {
    if ('response' in error && error.response?.status !== httpStatusCodes.CLIENT_ERROR.UNAUTHORIZED) {
        if (containsSecrets) {
            if (error.config) {
                error.config.data = null;
            }
            if (error.response && error.response.config) {
                error.response.config.data = null;
            }
        }
    }
    if (error.message === 'Network Error') {
        Logger.warn(`${message}: ${error.message}`, { error });
    } else {
        Logger.error(`${message}: ${error.message}`, { error });
    }
};
