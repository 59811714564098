import React, { FC, ReactElement } from 'react';

import { PremiumFeatures } from '../context/PremiumFeaturesContext';
import { usePremiumFeatureEnabled } from '../hooks/usePremiumFeatureEnabled';

interface PremiumFeatureContainerProps {
    feature: keyof PremiumFeatures;
    fallback?: ReactElement;
    children: React.ReactNode;
}

export const PremiumFeatureEnabled: FC<PremiumFeatureContainerProps> = ({ feature, fallback, children }) => {
    const isFeatureEnabled = usePremiumFeatureEnabled(feature);

    if (isFeatureEnabled) {
        return <>{children}</>;
    }

    return fallback ? fallback : null;
};
