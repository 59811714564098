import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { getSemanticValue, Link } from '@freenow/wave';

import { RemoteSettingsContext } from '../../common/context/RemoteSettingsContext';

const LoginFooter = styled.div`
    text-align: center;
    border-top: 1px solid ${getSemanticValue('border-neutral-default')};
    min-height: 28px;
    width: 100%;
`;

const defaultCountryCode = 'gb';

export const SignUpFooter = ({ countryCode, className = '' }: { countryCode?: string; className?: string }) => {
    const { getSettings } = useContext(RemoteSettingsContext);

    const settings = countryCode ? getSettings(countryCode) : getSettings(defaultCountryCode);

    if (!settings) {
        return null;
    }

    return (
        <LoginFooter className={className}>
            {settings.dataPrivacyLink ? (
                <Link fontSize={0} target="_blank" rel="noopener noreferrer" href={settings.dataPrivacyLink} m={1}>
                    <FormattedMessage id="signUp.createAccount.footer.dataPrivacy" />
                </Link>
            ) : null}
            {settings.legalNoteLink ? (
                <Link fontSize={0} target="_blank" rel="noopener noreferrer" href={settings.legalNoteLink} m={1}>
                    <FormattedMessage id="signUp.createAccount.footer.imprint" />
                </Link>
            ) : null}
            {settings.generalTermsConditions.link ? (
                <Link
                    fontSize={0}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={settings.generalTermsConditions.link}
                    m={1}
                >
                    <FormattedMessage id="signUp.createAccount.footer.termAndConditions" />
                </Link>
            ) : null}
        </LoginFooter>
    );
};
