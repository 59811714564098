import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const DURATION = 3000;
const DURATION_DELAY = DURATION / 3;
const RADIUS = 75;

const pulse = keyframes`
    0% {
        opacity: .5;
        transform: scale(0);
    }

    60% {
        opacity: .3;
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
`;

const PulsingCircle = styled.circle`
    animation-delay: ${(props) => props.delay}ms;
    animation-duration: ${DURATION}ms;
    animation-iteration-count: infinite;
    animation-name: ${pulse};
    transform-origin: center center;
`;

export const PulsingCircles = ({ fill }) => (
    <Fragment>
        <PulsingCircle cx={RADIUS} cy={RADIUS} r={RADIUS} opacity={0} fill={fill} delay={DURATION_DELAY} />
        <PulsingCircle cx={RADIUS} cy={RADIUS} r={RADIUS} opacity={0} fill={fill} delay={DURATION_DELAY * 2} />
        <PulsingCircle cx={RADIUS} cy={RADIUS} r={RADIUS} opacity={0} fill={fill} />
    </Fragment>
);

PulsingCircles.propTypes = {
    fill: PropTypes.string,
};
