import styled from 'styled-components';

import { getSemanticValue } from '@freenow/wave';

export const TableHeaderItem = styled.div<{ small: boolean }>`
    font-weight: bold;
    flex-basis: ${(props) => (props.small ? '2.5rem' : '6.25rem')};
    flex-grow: ${(props) => (props.small ? 0 : 1)};
    flex-shrink: 1;
    position: relative;
    color: ${getSemanticValue('foreground-primary')};
`;
