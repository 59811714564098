type Translations = Object;

export const translate = (translations: Translations = {}, ...keys: Array<string>) => {
    return translations[keys.join('.')] || '';
};

export const getBrowserLocale = () => {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    } else if (navigator['browserLanguage'] || navigator['systemLanguage'] || navigator['userLanguage']) {
        // only IE uses this
        return navigator['browserLanguage'] || navigator['systemLanguage'] || navigator['userLanguage'];
    } else {
        return navigator.language;
    }
};
