import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import {
    Button,
    Card,
    ChevronRightIcon,
    XCrossIcon,
    Headline,
    MediaQueries,
    Modal,
    Spaces,
    Text,
    getSemanticValue,
    Box,
} from '@freenow/wave';

import { trackGetStarted } from '../../tracking/helpers/googleTagManager/trackEvents';
import { ROUTES } from '../../routing/constants/routes';
import { useMatchMedia } from '../../common/hooks/useMatchMedia';
import { useFormatMessage } from '../../common/hooks/useFormatMessage';

const activeUspStyles = css`
    background-color: ${getSemanticValue('background-element-accent-faded')};
    color: ${getSemanticValue('foreground-accent-default')};
`;

const UspWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 12px;
    ${(props) => (props.active ? activeUspStyles : null)};

    &:hover {
        ${activeUspStyles};
    }

    order: 2;
    ${MediaQueries.large} {
        order: 1;
    }

    & svg {
        flex-shrink: 0;
        flex-grow: 0;
    }
`;

const CtaWrapper = styled.div`
    order: 1;
    margin-bottom: ${Spaces[5]};
    display: flex;
    justify-content: center;

    ${MediaQueries.large} {
        order: 2;
        margin-top: 80px;
        margin-bottom: 0;

        & > * {
            flex-basis: 100%;
        }
    }
`;

const RelativeBox = styled(Box)`
    width: calc(50% - 40px);
    position: absolute;
    z-index: 1;
    transition: transform 200ms ease;
    transform: ${(props) => (props.isVisible ? 'translateX(0)' : 'translateX(-100%)')};
    left: 50%;
    height: calc(100% - 128px);
`;

const LeftBox = styled(Box)`
    z-index: 2;
    display: flex;
    flex-direction: column;
`;

const AbsoluteXIcon = styled(XCrossIcon)`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

const UnorderedList = styled.ul`
    list-style: none;
`;

const ListItem = styled.li`
    margin-top: 16px;

    :nth-child(1) {
        margin-top: 0;
    }

    &::before {
        content: '\\2022';
        color: ${getSemanticValue('foreground-primary')};
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
`;

export const InfoBook = () => {
    const formatMessage = useFormatMessage();
    const location = useLocation();
    const [selectedUsp, setSelectedUsp] = useState(undefined);
    const [uspDescriptionVisible, setUspDescriptionVisible] = useState(false);
    const isLarge = useMatchMedia(MediaQueries.large);

    const uspList = [
        {
            usp: formatMessage('signUp.info.usp1.usp'),
            bulletPoints: [
                formatMessage('signUp.info.usp1.bulletPoint1', {}, ' '),
                formatMessage('signUp.info.usp1.bulletPoint2', {}, ' '),
                formatMessage('signUp.info.usp1.bulletPoint3', {}, ' '),
                formatMessage('signUp.info.usp1.bulletPoint4', {}, ' '),
                formatMessage('signUp.info.usp1.bulletPoint5', {}, ' '),
            ],
        },
        {
            usp: formatMessage('signUp.info.usp2.usp'),
            bulletPoints: [
                formatMessage('signUp.info.usp2.bulletPoint1', {}, ' '),
                formatMessage('signUp.info.usp2.bulletPoint2', {}, ' '),
                formatMessage('signUp.info.usp2.bulletPoint3', {}, ' '),
                formatMessage('signUp.info.usp2.bulletPoint4', {}, ' '),
                formatMessage('signUp.info.usp2.bulletPoint5', {}, ' '),
            ],
        },
        {
            usp: formatMessage('signUp.info.usp3.usp'),
            bulletPoints: [
                formatMessage('signUp.info.usp3.bulletPoint1', {}, ' '),
                formatMessage('signUp.info.usp3.bulletPoint2', {}, ' '),
                formatMessage('signUp.info.usp3.bulletPoint3', {}, ' '),
                formatMessage('signUp.info.usp3.bulletPoint4', {}, ' '),
                formatMessage('signUp.info.usp3.bulletPoint5', {}, ' '),
            ],
        },
        {
            usp: formatMessage('signUp.info.usp4.usp'),
            bulletPoints: [
                formatMessage('signUp.info.usp4.bulletPoint1', {}, ' '),
                formatMessage('signUp.info.usp4.bulletPoint2', {}, ' '),
                formatMessage('signUp.info.usp4.bulletPoint3', {}, ' '),
                formatMessage('signUp.info.usp4.bulletPoint4', {}, ' '),
                formatMessage('signUp.info.usp4.bulletPoint5', {}, ' '),
            ],
        },
        {
            usp: formatMessage('signUp.info.usp5.usp'),
            bulletPoints: [
                formatMessage('signUp.info.usp5.bulletPoint1', {}, ' '),
                formatMessage('signUp.info.usp5.bulletPoint2', {}, ' '),
                formatMessage('signUp.info.usp5.bulletPoint3', {}, ' '),
                formatMessage('signUp.info.usp5.bulletPoint4', {}, ' '),
                formatMessage('signUp.info.usp5.bulletPoint5', {}, ' '),
            ],
        },
    ];

    const resetSelectedUsp = () => {
        setUspDescriptionVisible(false);
        setSelectedUsp(undefined);
    };

    const showUspDescription = (newSelectedUsp) => () => {
        if (selectedUsp === newSelectedUsp) {
            setUspDescriptionVisible(false);
            setSelectedUsp(undefined);
        } else {
            setUspDescriptionVisible(true);
            setSelectedUsp(newSelectedUsp);
        }
    };

    const handleGetStarted = () => {
        trackGetStarted();
    };

    const renderUspDescription = (dismiss) => (
        <>
            <UnorderedList>
                {selectedUsp !== undefined && uspList[selectedUsp!!]
                    ? uspList[selectedUsp!!].bulletPoints.map((bulletPoint, index) => {
                          if (bulletPoint.trim()) {
                              return (
                                  <ListItem key={`usp-bullet-point-${index}`}>
                                      <Text>{bulletPoint}</Text>
                                  </ListItem>
                              );
                          }
                          return null;
                      })
                    : null}
            </UnorderedList>
            <Button variant="secondary" size="small" onClick={dismiss} mt={4} width={['100%', '100%', '100%', 'auto']}>
                {formatMessage('signUp.info.uspInfoClose')}
            </Button>
        </>
    );

    return (
        <>
            <LeftBox width={['100%', '100%', '100%', '50%']}>
                <Card level={0}>
                    <Headline as="h2" mb={2}>
                        {formatMessage('signUp.info.headline')}
                    </Headline>
                    <Text as="p" mb={5}>
                        {formatMessage('signUp.info.description')}
                    </Text>
                    {uspList.map((usp, index) => (
                        <UspWrapper
                            onClick={showUspDescription(index)}
                            key={`usp_${index}`}
                            active={selectedUsp === index && uspDescriptionVisible}
                        >
                            <Text as="p" fontWeight="semibold" style={{ color: 'inherit' }}>
                                {usp.usp}
                            </Text>
                            <ChevronRightIcon size={20} />
                        </UspWrapper>
                    ))}
                    <CtaWrapper>
                        <Link
                            to={
                                location.pathname === ROUTES.SIGN_UP
                                    ? ROUTES.SIGN_UP_CREATE_ACCOUNT
                                    : ROUTES.SECRET_SIGN_UP_CREATE_ACCOUNT
                            }
                        >
                            <Button onClick={handleGetStarted} width="100%" data-testid="sign-up-info-continue">
                                {formatMessage('signUp.info.ctaButton')}
                            </Button>
                        </Link>
                    </CtaWrapper>
                </Card>
            </LeftBox>
            {isLarge ? (
                <RelativeBox isVisible={uspDescriptionVisible}>
                    <Card level="100">
                        <AbsoluteXIcon onClick={resetSelectedUsp} />
                        {renderUspDescription(resetSelectedUsp)}
                    </Card>
                </RelativeBox>
            ) : (
                uspDescriptionVisible && (
                    <Modal onClose={resetSelectedUsp}>{(dismiss) => renderUspDescription(dismiss)}</Modal>
                )
            )}
        </>
    );
};
