import { config } from '@mytaxi/config-store';

import { authorizedAxiosInstance } from '../../common/api/axios-instance';
import {
    Address,
    BusinessAccountDetails,
    OnlineBookingDefaultPaymentType,
} from '../../common/context/BusinessAccountContext';

export interface BusinessAccountListEntry {
    companyId: number;
    companyName: string;
}

interface BusinessAccountListResponseData {
    businessAccountBasicList: BusinessAccountListEntry[];
}

interface BusinessAccountListResponse {
    data: BusinessAccountListResponseData;
}

export const getAvailableBusinessAccounts = (): Promise<BusinessAccountListResponse> => {
    const url = `${config.getItem('apgsUrl')}/v1/business-accounts`;
    return authorizedAxiosInstance.get(url);
};

export interface UpdateBusinessAccountInformationRequestBody {
    multiCreditCardAllowed: boolean;
    invoiceAddress: Address;
    contactPhone: string;
    contactEmail: string;
    projectNameRequired: boolean;
    costCenterRequired: boolean;
    notesMandatory: boolean;
    preventTip: boolean;
    forceBookerToEnterNameAndPhone: boolean;
    useBookerCostCenterForGuestBooking: boolean;
    sendSmsToBookerEnabled: boolean;
    onlineBookingDefaultPaymentType: OnlineBookingDefaultPaymentType;
    useInAppName: boolean;
    inAppName: string;
    tripHistoryReportLayoutId: string | null;
}

interface UpdateBusinessAccountInformationResponse {
    data: BusinessAccountDetails;
}

export const updateBusinessAccountInformation = (
    businessAccountId: number,
    requestBody: Partial<UpdateBusinessAccountInformationRequestBody>,
): Promise<UpdateBusinessAccountInformationResponse> => {
    const url = `${config.getItem('apgsUrl')}/v2/business-accounts/${businessAccountId}`;
    return authorizedAxiosInstance.patch(url, requestBody);
};

interface EmailAddressList {
    emailAddressList: string[];
}

interface updateReceiptForwardingEmailAddressesResponse {
    data: EmailAddressList;
}

export const updateReceiptForwardingEmailAddresses = (
    businessAccountId: number,
    emailAddressList: string[],
): Promise<updateReceiptForwardingEmailAddressesResponse> => {
    const url = `${config.getItem('apgsUrl')}/v1/receipt-forwarding-email-addresses/${businessAccountId}`;
    const requestBody = {
        emailAddressList,
    };

    return authorizedAxiosInstance.put(url, requestBody);
};
