import { config } from '@mytaxi/config-store';

import { getBrowserLocale } from './translation';

export const determineBrowserLocale = (manual) => {
    let locale;
    const browserLocale = getBrowserLocale();

    if (browserLocale) {
        locale = browserLocale;
    }

    if (manual) {
        locale = manual;
    }

    if (config.getItem('availableLocales').find((l) => l.includes(locale))) {
        return config.getItem('availableLocales').find((l) => l.includes(locale));
    } else if (config.getItem('availableLocales').find((l) => l.includes(locale.substr(0, 2)))) {
        return config.getItem('availableLocales').find((l) => l.includes(locale.substr(0, 2)));
    } else {
        return 'en-GB';
    }
};
