import storage from 'local-storage-fallback';
import { randomString } from '../../../util/randomString';
import LOCAL_STORAGE_KEYS from '../../common/constants/common.localStorageKeys';

function getAvailableStateValues<PayloadType>(): { [state: string]: PayloadType } {
    const stateListString = storage.getItem(LOCAL_STORAGE_KEYS.AUTHENTICATION_STATE_VALUES);

    if (!stateListString) {
        return {};
    }

    return JSON.parse(stateListString);
}

function generateAndStoreState<PayloadType>(payload: PayloadType) {
    const generatedNonce = randomString(12);
    const existingStates = getAvailableStateValues<PayloadType>();

    existingStates[generatedNonce] = payload;

    storage.setItem(LOCAL_STORAGE_KEYS.AUTHENTICATION_STATE_VALUES, JSON.stringify(existingStates));

    return generatedNonce;
}

function getStoredState<PayloadType>(nonce: string): PayloadType | null {
    const existingStates = getAvailableStateValues<PayloadType>();
    const existingPayload = existingStates[nonce];

    if (!existingPayload) {
        return null;
    }

    return existingPayload;
}

export { generateAndStoreState, getStoredState };
