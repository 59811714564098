import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';

import { ROUTES } from '../../routing/constants/routes';
import { SignUpContext } from '../context/SignUpContext';

export const SignUpStepChecker = ({ children }) => {
    const history = useHistory();
    const { signUpSteps } = useContext(SignUpContext);

    const signUpStepOrder = {
        [ROUTES.SIGN_UP]: 1,
        [ROUTES.SIGN_UP_CREATE_ACCOUNT]: 1,
        [ROUTES.SIGN_UP_EXISTING_PASSENGER_ACCOUNT]: 2,
        [ROUTES.SIGN_UP_LOGIN]: 2,
        [ROUTES.SIGN_UP_VERIFY_PHONE_NUMBER]: 3,
        [ROUTES.SIGN_UP_ADD_PAYMENT_METHOD]: 4,
    };

    const secretSignUpStepOrder = {
        [ROUTES.SECRET_SIGN_UP]: 1,
        [ROUTES.SECRET_SIGN_UP_CREATE_ACCOUNT]: 1,
        [ROUTES.SECRET_SIGN_UP_EXISTING_PASSENGER_ACCOUNT]: 2,
        [ROUTES.SECRET_SIGN_UP_LOGIN]: 2,
        [ROUTES.SECRET_SIGN_UP_VERIFY_PHONE_NUMBER]: 3,
        [ROUTES.SECRET_SIGN_UP_ADD_PAYMENT_METHOD]: 4,
    };

    const selectRedirectRoute = () => {
        if (Object.keys(signUpStepOrder).includes(history.location.pathname)) {
            const requestedSignUpStepNumber = signUpStepOrder[history.location.pathname];
            const maxAllowedStepNumber = Math.max(...signUpSteps.map((stepRoute) => signUpStepOrder[stepRoute]), 1);

            const routeAllowed = requestedSignUpStepNumber && requestedSignUpStepNumber <= maxAllowedStepNumber;

            if (routeAllowed) {
                return null;
            }

            return Object.keys(signUpStepOrder).find((key) => signUpStepOrder[key] === maxAllowedStepNumber);
        }

        if (Object.keys(secretSignUpStepOrder).includes(history.location.pathname)) {
            const requestedSignUpStepNumber = secretSignUpStepOrder[history.location.pathname];
            const maxAllowedStepNumber = Math.max(
                ...signUpSteps.map((stepRoute) => secretSignUpStepOrder[stepRoute]),
                1,
            );

            const routeAllowed = requestedSignUpStepNumber && requestedSignUpStepNumber <= maxAllowedStepNumber;

            if (routeAllowed) {
                return null;
            }

            return Object.keys(secretSignUpStepOrder).find(
                (key) => secretSignUpStepOrder[key] === maxAllowedStepNumber,
            );
        }

        return null;
    };

    const redirectRoute = selectRedirectRoute();

    if (redirectRoute) {
        return <Redirect to={redirectRoute} />;
    } else {
        return children;
    }
};
