import localStorage from 'local-storage-fallback';
import React from 'react';

const LAST_RELOAD_KEY = 'chunk-load-error-last-refresh-timestamp';
const FIFTEEN_SECONDS = 15000;

export class ChunkLoadErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
    constructor(props) {
        super(props);

        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        if (error.name === 'ChunkLoadError') {
            const now = new Date().getTime();
            const lastReloadTimestamp = localStorage.getItem(LAST_RELOAD_KEY);

            if (lastReloadTimestamp && now - FIFTEEN_SECONDS < Number.parseInt(lastReloadTimestamp)) {
                throw error;
            }

            localStorage.setItem(LAST_RELOAD_KEY, now.toString());
            this.setState({ hasError: true });
        } else {
            throw error;
        }
    }

    render() {
        if (this.state.hasError) {
            window.location.reload();

            return null;
        }

        return this.props.children;
    }
}
