import Logger from '@mytaxi/logging';
import { trackPageView } from '../../tracking/helpers/googleTagManager/trackPageView';
import { useHistory } from 'react-router';

export const PathTracking = () => {
    const history = useHistory();

    history.listen((location) => {
        const pathname = location.pathname;
        Logger.setMetadata('pathname', pathname);
        Logger.setMetadata('querystring', location.search);

        Logger.debug(`Navigated to ${pathname}`, location);

        trackPageView(pathname);
    });

    return null;
};
