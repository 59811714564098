export const httpStatusCodes = {
    INFORMATIONAL: {},
    SUCCESSFUL: {
        OK: 200,
        PARTIAL_CONTENT: 206,
    },
    REDIRECTION: {
        FOUND: 302,
    },
    CLIENT_ERROR: {
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
        NOT_FOUND: 404,
        CONFLICT: 409,
        GONE: 410,
        PRECONDITION_FAILED: 412,
    },
    SERVER_ERROR: {},
};
