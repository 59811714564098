import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, getSemanticValue } from '@freenow/wave';

const ButtonWrapper = styled.div`
    position: relative;
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
`;

const Title = styled.span`
    padding-right: 6px;
`;

export enum DROP_DOWN_PLACEMENT {
    LEFT = 'left',
    RIGHT = 'right',
}

const DropdownWrapper = styled.div`
    position: absolute;
    background-color: ${getSemanticValue('background-page-elevation-1')};
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 6px 0;
    margin-top: 4px;
    overflow-y: auto;
    z-index: 1;

    ${(props) =>
        props.placement === DROP_DOWN_PLACEMENT.LEFT &&
        css`
            left: 2px;
        `}

    ${(props) =>
        props.placement === DROP_DOWN_PLACEMENT.RIGHT &&
        css`
            right: 2px;
        `}
`;

export const DropdownItem = styled.div`
    padding: 8px 32px;
    font-size: 16px;
    white-space: nowrap;

    color: ${(props) =>
        props.disabled ? getSemanticValue('foreground-disabled') : getSemanticValue('foreground-primary')};

    &:hover {
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
        background-color: ${(props) =>
            props.disabled ? 'transparent' : getSemanticValue('background-element-accent-faded')};
    }
`;

interface DropdownButtonProps {
    title: string;
    children: React.ReactNode;
    placement?: DROP_DOWN_PLACEMENT;
    buttonSize?: string;
}

export const DropdownButton = ({
    title,
    children,
    placement = DROP_DOWN_PLACEMENT.LEFT,
    buttonSize = 'small',
}: DropdownButtonProps) => {
    const [open, setOpen] = useState(false);
    const dropdownButtonRef = useRef<HTMLDivElement>(null);

    const toggleOpen = () => setOpen(!open);

    useEffect(() => {
        const handleClickOutside = ({ target }) => {
            if (dropdownButtonRef.current && !dropdownButtonRef.current.contains(target)) {
                // delay closing of options to wait for event handlers of children
                setImmediate(() => setOpen(false));
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [setOpen]);

    return (
        <ButtonWrapper>
            <div ref={dropdownButtonRef}>
                <Button
                    onClick={toggleOpen}
                    variant="secondary"
                    size={buttonSize}
                    style={{ minWidth: '0' }}
                    data-testid="dropdown-button"
                >
                    {title && <Title>{title}</Title>}
                    •••
                </Button>
            </div>
            {open ? <DropdownWrapper placement={placement}>{children}</DropdownWrapper> : null}
        </ButtonWrapper>
    );
};
