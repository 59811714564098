import React, { createContext, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { config } from '@mytaxi/config-store';
import Logger from '@mytaxi/logging';
import { ErrorPanel } from '@mytaxi/react-error-screens';
import { Button, Headline } from '@freenow/wave';

import { getAvailableBusinessAccounts } from '../../account/api/business-account-information.api';
import { AuthenticationContext } from '../../authentication/context/AuthenticationContext';
import { LoginWrapper } from '../../signUp/components/LoginWrapper';
import { WideLoginContent } from '../../signUp/components/WideLoginContent';
import { trackLoginError } from '../../tracking/helpers/googleTagManager/trackEvents';
import { KYCStatus } from '../../account/hooks/useKYCStatus';
import { authorizedAxiosInstance } from '../api/axios-instance';
import { CenteredLoadingIndicator, ErrorScreen } from '../components';
import LOCAL_STORAGE_KEYS from '../constants/common.localStorageKeys';
import { useFormatMessage } from '../hooks/useFormatMessage';
import { ExternalPaymentSolution } from '../types/ExternalPaymentSolution';

interface BusinessAccountList {
    id: number;
    name: string;
}

export enum InvoicingScheduleType {
    PER_TRIP = 'PER_TRIP',
    END_OF_MONTH = 'END_OF_MONTH',
}

export enum OnlineBookingDefaultPaymentType {
    CASH = 'CASH',
    ACCOUNT = 'ACCOUNT',
}

export enum BenefitsType {
    MoBuInvoicing = 'MOBU_INVOICING',
    BenefitsCard = 'BENEFITS_CARD',
    NoBenefits = 'NO_BENEFITS',
}

export interface Address {
    addressLine: string;
    city: string;
    cityCode: string;
    countryCode: string;
}

export interface BusinessAccountDetails {
    id: number;
    officeId: number;
    officeHeadquarterCurrency?: string;
    name: string;
    legalName?: string;
    taxId?: string;
    vatId?: string;
    allowReceiptForwarding: boolean;
    contactEmail?: string;
    contactPhone?: string;
    commercialRegisterNumber?: string;
    specialAccountNumber?: string;
    address?: Address;
    invoiceEmailAddress?: string;
    invoiceAddress?: Address;
    verified: boolean;
    countryCode: string;
    locale: string;
    contactPerson?: {
        firstName: string;
        lastName: string;
        gender?: string;
        emailAddress: string;
        phone: string;
    };
    testAccount: boolean;
    settings: {
        preventTip: boolean;
        costCenterRequired: boolean;
        projectNameRequired: boolean;
        multiCreditCardAllowed: boolean;
        viewHistoryForBookers: boolean;
        invoicingScheduleType: InvoicingScheduleType;
        accessHistoryForBookers: string;
        useRideReferenceList: boolean;
        servicePlan: 'BASIC' | 'PREMIUM';
        invoicingFormat: string;
        tripHistoryReportLayoutId: string;
        notesMandatory: boolean;
        useInAppName: boolean;
        inAppName: string;
        onlineBookingDefaultPaymentType: OnlineBookingDefaultPaymentType;
        sendSmsToBookerEnabled: boolean;
        useBookerCostCenterForGuestBooking: boolean;
        forceBookerToEnterNameAndPhone: boolean;
        bookingEnabled: boolean;
    };
    pecOfficialEmailAddress?: string;
    codiceUnivocoId?: string;
    billingAllowed: boolean;
    companyType: string;
    businessAccountType?: 'COMPANY' | 'HOTEL' | 'DISPATCH' | 'RESTAURANT';
    mobilityBudgetEnabled: boolean;
    referenceListManagementEnabled: boolean;
    employeeBenefitsBusinessAccount: boolean;
    benefitsType: BenefitsType;
    benefitsCardAccountDetails?: {
        kycStatus?: KYCStatus;
    };
    jobTicketEnabled: boolean;
}

interface ExternalPaymentSolutionResponse {
    externalPaymentSolution: ExternalPaymentSolution;
}
interface MultiMobilityDetailsResponse {
    allowed: boolean;
}

export interface IBusinessAccountContext {
    externalPaymentSolution: ExternalPaymentSolution;
    multiMobilityDetails?: MultiMobilityDetailsResponse;
    currentBusinessAccount?: BusinessAccountDetails;
    setCurrentBusinessAccountId: (businessAccountId: number) => void;
    updateCurrentBusinessAccount: (businessAccount: BusinessAccountDetails) => void;
    businessAccounts?: BusinessAccountList[];
}

export const BusinessAccountContext = createContext<IBusinessAccountContext>({
    setCurrentBusinessAccountId: () => {
        throw Error('BusinessAccountContext is not initialized.');
    },
    updateCurrentBusinessAccount: () => {
        throw Error('BusinessAccountContext is not initialized.');
    },
    businessAccounts: [],
    externalPaymentSolution: ExternalPaymentSolution.NONE,
});

export const BusinessAccountContextProvider = (props: { children: React.ReactNode }) => {
    const formatMessage = useFormatMessage();
    const { logout } = useContext(AuthenticationContext);
    const [currentBusinessAccount, setCurrentBusinessAccount] = useState<BusinessAccountDetails | undefined>();
    const [currentBusinessAccountId, setCurrentBusinessAccountId] = useState<number | undefined>();
    const [businessAccounts, setBusinessAccounts] = useState<BusinessAccountList[] | undefined>(undefined);
    const [businessAccountListError, setBusinessAccountListError] = useState(false);
    const [businessAccountDetailsError, setBusinessAccountDetailsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [externalPaymentSolution, setExternalPaymentSolution] = useState<ExternalPaymentSolution>(
        ExternalPaymentSolution.NONE,
    );
    const [multiMobilityDetails, setMultiMobilityDetails] = useState<MultiMobilityDetailsResponse>();

    useEffect(() => {
        getAvailableBusinessAccounts()
            .then((response) => {
                if (response && response.data) {
                    const businessAccountsResponse = response.data.businessAccountBasicList.map((it) => ({
                        id: it.companyId,
                        name: it.companyName,
                    }));

                    setBusinessAccounts(businessAccountsResponse);

                    const lastBusinessAccountId = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_BUSINESS_ACCOUNT_ID);

                    const newBusinessAccount = lastBusinessAccountId
                        ? businessAccountsResponse.find((it) => it.id === Number.parseInt(lastBusinessAccountId))
                        : businessAccountsResponse[0];

                    if (newBusinessAccount) {
                        setCurrentBusinessAccountId(newBusinessAccount.id);
                    }
                }
            })
            .catch((error) => {
                Logger.error('Failed to fetch available business accounts', { error });
                setBusinessAccountListError(true);
            });
    }, []);

    useEffect(() => {
        if (currentBusinessAccountId) {
            setLoading(true);
            const businessAccountInformationRequest = authorizedAxiosInstance.get<BusinessAccountDetails>(
                `${config.getItem('apgsUrl')}/v2/business-accounts/${currentBusinessAccountId}`,
            );

            const externalPaymentSolutionRequest = authorizedAxiosInstance.get<ExternalPaymentSolutionResponse>(
                `${config.getItem(
                    'apgsUrl',
                )}/v1/business-accounts/${currentBusinessAccountId}/external-payment-solution`,
            );

            const multiMobilityDetailsRequest = authorizedAxiosInstance.get<MultiMobilityDetailsResponse>(
                `${config.getItem('apgsUrl')}/v1/business-accounts/${currentBusinessAccountId}/multi-mobility-details`,
            );

            Promise.all([
                businessAccountInformationRequest,
                externalPaymentSolutionRequest,
                multiMobilityDetailsRequest,
            ])
                .then(
                    ([
                        businessAccountInformationResponse,
                        externalPaymentSolutionResponse,
                        multiMobilityDetailsResponse,
                    ]) => {
                        setExternalPaymentSolution(externalPaymentSolutionResponse.data.externalPaymentSolution);

                        setMultiMobilityDetails(multiMobilityDetailsResponse.data);

                        setCurrentBusinessAccount(businessAccountInformationResponse.data);
                    },
                )
                .catch((error) => {
                    Logger.error(`Failed to fetch business account details for BA: ${currentBusinessAccountId}`, {
                        error,
                    });

                    setBusinessAccountDetailsError(true);
                });

            Logger.setMetadata('businessAccountId', currentBusinessAccountId);
        } else {
            Logger.removeMetadata('businessAccountId');
        }
    }, [currentBusinessAccountId]);

    if (loading) {
        if (
            (currentBusinessAccountId && currentBusinessAccount) ||
            businessAccounts?.length === 0 ||
            businessAccountDetailsError ||
            businessAccountListError
        ) {
            setLoading(false);
        }

        return <CenteredLoadingIndicator />;
    }

    if (businessAccountListError) {
        return (
            <ErrorPanel
                title={formatMessage('businessAccountContext.failedToLoadBusinessAccounts')}
                subTitle={formatMessage('errorPanel.header.headerSubTitle')}
                detailsTitle=""
                buttonTitle=""
                errorStack=""
                showMoreInformation={false}
            />
        );
    }

    if (businessAccountDetailsError) {
        return (
            <ErrorPanel
                title={formatMessage('businessAccountContext.failedToLoadBusinessAccountDetails')}
                subTitle={formatMessage('errorPanel.header.headerSubTitle')}
                detailsTitle=""
                buttonTitle=""
                errorStack=""
                showMoreInformation={false}
            />
        );
    }

    if (businessAccounts?.length === 0) {
        trackLoginError('Not an Admin');

        return (
            <LoginWrapper>
                <WideLoginContent>
                    <ErrorScreen>
                        <Headline as="h4" mb={2}>
                            <FormattedMessage id="login.errors.noAdmin" />
                        </Headline>
                        <Button variant="secondary" size="small" onClick={() => logout()}>
                            <FormattedMessage id="businessAccountContext.logout" />
                        </Button>
                    </ErrorScreen>
                </WideLoginContent>
            </LoginWrapper>
        );
    }

    if (!currentBusinessAccountId && businessAccounts) {
        Logger.debug(`Not an administrator for ${currentBusinessAccount?.id}: switching to ${businessAccounts[0].id}.`);

        setCurrentBusinessAccountId(businessAccounts[0].id);

        return null;
    }

    const updateCurrentBusinessAccountId = (businessAccountId: number) => {
        setCurrentBusinessAccountId(businessAccountId);
        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_BUSINESS_ACCOUNT_ID, businessAccountId.toString());
    };

    const updateCurrentBusinessAccount = (businessAccount: BusinessAccountDetails) => {
        setCurrentBusinessAccount(businessAccount);
    };

    const context: IBusinessAccountContext = {
        currentBusinessAccount,
        externalPaymentSolution,
        multiMobilityDetails,
        setCurrentBusinessAccountId: updateCurrentBusinessAccountId,
        updateCurrentBusinessAccount,
        businessAccounts,
    };

    return <BusinessAccountContext.Provider {...props} value={context} />;
};
