import { config } from '@mytaxi/config-store';
import log from '@mytaxi/logging';

import { startAuthentication } from '../../../authentication/util/startAuthentication';
import { axiosInstance } from '../axios-instance';
import { determineBrowserLocale } from '../../helpers/determineBrowserLocale';

interface Session {
    accessToken: string;
    refreshToken: string;
}

let refreshCall: Promise<Session> | null = null;

const getAccessTokenWithRefreshToken = (token) => {
    const url = `${config.getItem('wagsUrl')}/v1/oauth/token`;

    const params = new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: token,
        client_id: config.getItem('wagsClientId'),
    });

    const requestConfig = {
        auth: {
            username: config.getItem('wagsClientId'),
            password: '',
        },
    };

    return axiosInstance.post(url, params.toString(), requestConfig);
};

async function refreshAuthentication(refreshToken): Promise<Session> {
    try {
        const response = await getAccessTokenWithRefreshToken(refreshToken);
        const { access_token: accessToken, refresh_token: updatedRefreshToken } = response.data;

        log.debug('Successfully refreshed and stored access token');

        return {
            accessToken,
            refreshToken: updatedRefreshToken,
        };
    } catch (error) {
        log.info('Failed to refresh the access token, the refresh token might be expired', { error });

        startAuthentication(window.location.pathname + window.location.search);

        throw error;
    }
}

function refreshAccessToken(refreshToken, setSession) {
    log.debug('Refreshing access token using the refresh token from interceptor');

    return refreshAuthentication(refreshToken).then((tokenResponse) => {
        if (tokenResponse) {
            setSession({ ...tokenResponse });
            return Promise.resolve(tokenResponse);
        }

        return Promise.reject();
    });
}

function isTokenExpired(tokenString) {
    const base64Url = tokenString.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
    );

    const tokenData = JSON.parse(jsonPayload);

    return tokenData.exp < Math.floor(Date.now() / 1000);
}

export function addAuthenticatedInterceptors(axios, session, setSession) {
    const addXMytApplicationHeaderInterceptor = axios.interceptors.request.use((config) => {
        config.headers['X-Myt-Application'] = `AdminPanel/${process.env.REACT_APP_VERSION}`;

        return config;
    });

    const addAcceptLanguageInterceptor = axios.interceptors.request.use((config) => {
        config.headers['Accept-Language'] = determineBrowserLocale();

        return config;
    });

    const addAccessTokenToHeader = axios.interceptors.request.use((config) => {
        const accessToken = session.accessToken;

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        } else {
            log.info('Not adding authorization header in axios interceptor: access token is missing');
        }

        return config;
    });

    const startTokenFlowIfRefreshTokenIsExpired = axios.interceptors.request.use((config) => {
        const refreshToken = session.refreshToken;

        if (!refreshToken || isTokenExpired(refreshToken)) {
            startAuthentication(window.location.pathname + window.location.search);
        }

        return config;
    });

    const refreshTokenIfAccessTokenIsExpired = axios.interceptors.response.use(
        (res) => res,
        (error) => {
            if (!error.response || (error.response.status && +error.response.status !== 401)) {
                return Promise.reject(error);
            }

            log.debug(
                'Received 401 response in passenger authentication interceptor, will try to refresh access token',
            );

            refreshCall = refreshCall || refreshAccessToken(session.refreshToken, setSession);

            return refreshCall
                .then(({ accessToken }) => {
                    error.config.headers.Authorization = `Bearer ${accessToken}`;

                    return axiosInstance.request(error.config);
                })
                .finally(() => {
                    refreshCall = null;
                });
        },
    );

    return () => {
        axios.interceptors.request.eject(addAccessTokenToHeader);
        axios.interceptors.request.eject(startTokenFlowIfRefreshTokenIsExpired);
        axios.interceptors.request.eject(addXMytApplicationHeaderInterceptor);
        axios.interceptors.request.eject(addAcceptLanguageInterceptor);
        axios.interceptors.response.eject(refreshTokenIfAccessTokenIsExpired);
    };
}
