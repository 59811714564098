import styled from 'styled-components';

import { MediaQueries } from '@freenow/wave';

export const SignUpContent = styled.div`
    display: flex;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    flex-direction: column;
    flex-grow: 1;

    flex-wrap: wrap;

    ${MediaQueries.medium} {
        padding: 0 40px 64px;
    }

    ${MediaQueries.large} {
        padding-top: 64px;
        flex-direction: row;
    }
`;
