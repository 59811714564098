import { AxiosHeaders, AxiosInstance } from 'axios';

export const addXMytApplicationHeaderInterceptor = (axios: AxiosInstance) => {
    axios.interceptors.request.use((request) => {
        if (!request.headers) {
            request.headers = new AxiosHeaders();
        }
        request.headers['X-Myt-Application'] = `AdminPanel/${process.env.REACT_APP_VERSION}`;

        return request;
    });

    return axios;
};
