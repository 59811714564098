import React, { useRef } from 'react';
import TagsInput from 'react-tagsinput';
import AutosizeInput from 'react-18-input-autosize';
import styled from 'styled-components';

import { getSemanticValue, Tag } from '@freenow/wave';

const Wrapper = styled.div`
    & .react-tagsinput {
        border: 0.0625rem solid ${getSemanticValue('border-neutral-default')};
        overflow: auto;
        padding: 0.75rem;
        height: 7.25rem;
        border-radius: 0.25rem;

        &:active,
        &:focus,
        &--focused {
            border-color: ${getSemanticValue('border-focus')};
            box-shadow: inset 0 0 0 0.0625rem ${getSemanticValue('border-focus')};
        }

        & input {
            color: ${getSemanticValue('foreground-primary')};
            font-size: 1rem;
            font-weight: 400;

            &::placeholder {
                color: ${getSemanticValue('foreground-neutral-default')};
            }
        }
    }
`;

interface Key {
    controlRepresentation: string;
    names: string[];
}

interface Props {
    onTagsChange: (tags: string[]) => void;
    onInputChange: (tag: string) => void;
    tags: string[];
    inputValue: string;
    placeholderText: string;
    inputValidationRegex: RegExp;
    separationKeys: Key[];
    dataTestId?: string;
}

export const InputTagsField = (props: Props) => {
    const tagsInputField = useRef<HTMLDivElement>(null);

    const onTagsChange = (tags) => {
        props.onTagsChange(tags);
    };

    const onValidationReject = (tags) => {
        const lastTag = tags[tags.length - 1];
        props.onInputChange(lastTag);
    };

    const pasteSplit = (data) => {
        const separators = props.separationKeys.map((it) => it.names).flat();
        return data.split(new RegExp(separators.join('|'))).map((d) => d.trim());
    };

    const focusInput = () => {
        tagsInputField.current?.focus();
    };

    const renderInput = (inputProps) => {
        const { onChange, value, onPaste, placeholder, onKeyDown } = inputProps;
        const updateInputValueAndTags = (event) => {
            props.onInputChange(event.target.value);
            onChange(event);
        };
        return (
            <AutosizeInput
                autoFocus
                inputStyle={{ fontSize: 14, fontWeight: 400 }}
                type="text"
                onChange={updateInputValueAndTags}
                value={value}
                onPaste={onPaste}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                ref={tagsInputField}
            />
        );
    };

    const renderTag = (tagProps) => {
        const { tag, getTagDisplayValue, onRemove, disabled, key } = tagProps;

        const value = getTagDisplayValue(tag);

        return (
            <Tag key={key} onDismiss={() => onRemove(key)} dismissible={!disabled}>
                {value}
            </Tag>
        );
    };

    const inputProps = {
        className: 'react-tagsinput-input',
        placeholder: props.placeholderText,
        dataTestId: props.dataTestId,
    };

    return (
        <Wrapper onClick={focusInput} data-testid={props.dataTestId}>
            <TagsInput
                addOnPaste
                pasteSplit={pasteSplit}
                value={props.tags}
                onChange={onTagsChange}
                addKeys={props.separationKeys.map((it) => it.controlRepresentation)}
                inputProps={inputProps}
                currentValue={props.inputValue}
                renderInput={renderInput}
                renderTag={renderTag}
                validationRegex={props.inputValidationRegex}
                onClick={focusInput}
                onValidationReject={onValidationReject}
            />
        </Wrapper>
    );
};
