import storage from 'local-storage-fallback';
import { v4 as uuidv4 } from 'uuid';

import Logger from '@mytaxi/logging';
import { config } from '@mytaxi/config-store';

const BROWSER_SESSION_ID_KEY = 'browser-session-id';

export function initializeLogging() {
    let browserSessionId = storage.getItem(BROWSER_SESSION_ID_KEY);

    if (!browserSessionId) {
        browserSessionId = uuidv4();
        storage.setItem(BROWSER_SESSION_ID_KEY, browserSessionId);
    }

    Logger.configure({
        endpoint: config.getItem('loggingUrl'),
        application: 'BusinessAccountWebApp',
        logToConsole: process.env.NODE_ENV === 'development',
        defaultMetadata: {
            browserSessionId,
            version: process.env.REACT_APP_VERSION,
            userAgent: navigator?.userAgent,
        },
    });
}

export function resetBrowserSessionId() {
    storage.removeItem(BROWSER_SESSION_ID_KEY);
}
