const LOCAL_STORAGE_KEYS = {
    STORE_LOCAL_BUSINESS_ACCOUNT_DRAFT: 'state::localBusinessAccountDraft',
    CURRENT_BUSINESS_ACCOUNT_ID: 'currentBusinessAccountId',
    ACCESS_TOKEN: 'accessToken',
    REFRESH_TOKEN: 'refreshToken',
    QUESTIONNAIRE_COUNT: 'questionnaireCount',
    QUESTIONNAIRE_LAST_SEEN_DATE: 'questionnaireLastSeenDate',
    ALLOWED_SIGN_UP_STEPS: 'allowedSignUpSteps',
    RECEIPT_BULK_DOWNLOAD_OPERATION_ID: 'receiptBulkDownloadOperationId',
    SIGN_UP_TRACKING_PARAMS: 'signUpTrackingParams',
    AUTHENTICATION_STATE_VALUES: 'authenticationStateValues',
    LAST_KYC_STATUS_MODAL_SHOWN_DATE: 'lastKYCStatusModalShownDate',
    LAST_FUNDING_ADVICE_MODAL_SHOWN_DATE: 'lastfundingAdviceModalShownDate',
};

export default LOCAL_STORAGE_KEYS;
