import React from 'react';
import styled, { keyframes } from 'styled-components';

import { getSemanticValue } from '@freenow/wave';

import { PulsingCircles } from './PulsingCircles';

/* eslint-disable max-len */

const shake = keyframes`
  10%, 90% {
    transform: translateX(-1px);
  }

  20%, 80% {
    transform: translateX(2px);
  }

  30%, 50%, 70% {
    transform: translateX(-4px);
  }

  40%, 60% {
    transform: translateX(4px);
  }
`;

const ShakeAnimation = styled.g`
    animation: ${shake} 0.6s ease both;
    transform: translateX(0);
`;

const PhoneIllustration = () => (
    <ShakeAnimation>
        <path
            fill={getSemanticValue('foreground-danger-default')}
            stroke={getSemanticValue('foreground-on-background-neutral')}
            strokeWidth="3.083"
            d="M51.063 33.87l32.713-5.768c5.31-.936 10.372 2.609 11.308 7.918l12.113 68.696c.936 5.31-2.609 10.372-7.918 11.308l-32.713 5.768c-5.309.937-10.372-2.608-11.308-7.917L43.145 45.178c-.936-5.309 2.61-10.372 7.918-11.308z"
        />
        <path
            fill={getSemanticValue('foreground-on-background-neutral')}
            fillRule="nonzero"
            d="M51.197 35.412a8.084 8.084 0 0 0-6.557 9.365l12.136 68.83a8.22 8.22 0 0 0 9.522 6.668l32.714-5.769a8.22 8.22 0 0 0 6.667-9.521L93.46 35.686a7.61 7.61 0 0 0-8.815-6.172l-6.044 1.066a2.388 2.388 0 0 0-1.872 1.665 2.388 2.388 0 0 1-1.873 1.665l-12.463 2.198a2.414 2.414 0 0 1-2.345-.922 2.414 2.414 0 0 0-2.345-.921l-6.506 1.147zm11.046-2.408l11.676-2.058a5.47 5.47 0 0 1 4.147-3.402l6.044-1.065c5.815-1.026 11.36 2.857 12.386 8.672l12.219 69.299c1.084 6.146-3.02 12.008-9.167 13.092l-32.715 5.768c-6.147 1.084-12.008-3.02-13.092-9.167L41.604 45.312c-1.07-6.073 2.984-11.865 9.058-12.936l6.506-1.147a5.496 5.496 0 0 1 5.075 1.775z"
        />
        <g transform="rotate(-10 380.298 -294.978)">
            <path
                fill={getSemanticValue('white')}
                d="M16 11.476l7.54-7.54a3.199 3.199 0 0 1 4.523 4.525L20.523 16l7.54 7.54a3.199 3.199 0 0 1-4.524 4.523L16 20.523l-7.54 7.54a3.199 3.199 0 0 1-4.523-4.524L11.477 16l-7.54-7.54A3.199 3.199 0 1 1 8.46 3.938L16 11.477z"
            />
        </g>
    </ShakeAnimation>
);

export const ErrorIllustration = () => (
    <svg width="150" height="150">
        <PulsingCircles fill={getSemanticValue('foreground-danger-default')} />
        <PhoneIllustration />
    </svg>
);
