import React, { createContext, useContext, useEffect, useState } from 'react';

import { TranslationContext } from '@mytaxi/dynamic-translations';

import { determineBrowserLocale } from '../helpers/determineBrowserLocale';
import { TranslationContextProvider } from './TranslationContext';

export interface ILocaleContext {
    locale: string;
    setLocale: (string) => void;
}

export const LocaleContext = createContext<ILocaleContext>({
    locale: '',
    setLocale: () => {
        throw new Error('LocaleContext not initialized');
    },
});

export const LocaleContextProvider = ({ children }: { children: React.ReactNode }) => {
    const translationContext = useContext(TranslationContext);
    const [locale, updateLocale] = useState(determineBrowserLocale());

    const setLocale = (requestedLocale) => {
        updateLocale(requestedLocale);
        translationContext.setLocale(requestedLocale);
    };

    useEffect(() => {
        translationContext.setLocale(locale);
    }, [locale, translationContext]);

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            <TranslationContextProvider locale={locale}>{children}</TranslationContextProvider>
        </LocaleContext.Provider>
    );
};
