import React, { FC } from 'react';
import styled from 'styled-components';

const Headline = styled.h3<{ clickable: boolean }>`
    display: inline;
    user-select: none;
    cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};
`;

interface Props {
    title: string;
    onClick?: () => void;
    className?: string;
    clickable?: boolean;
}

export const TableHeaderHeadline: FC<Props> = ({ title, onClick, className = '', clickable = false }) => (
    <Headline className={className} clickable={clickable}>
        <span onClick={onClick}>{title}</span>
    </Headline>
);
