import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { LightScheme, ModernColors } from '@freenow/wave';

import App from './App';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://dcc466d38952450584ed47a8d60d4d82@o263572.ingest.sentry.io/5421025',
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 1.0,
        release: `admin-panel@${process.env.REACT_APP_VERSION}`,
        environment: window.environmentConfig.environment,
    });
}

const container = document.getElementById('root'); // find the DOM node to mount
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <>
            <ModernColors />
            <LightScheme>
                <App />
            </LightScheme>
        </>
    </React.StrictMode>,
); // render the app
