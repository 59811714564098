import { config } from '@mytaxi/config-store';
import { ROUTES } from '../../routing/constants/routes';
import { IStatePayload } from '../context/AuthenticationContext';
import { generateAndStoreState } from './authenticationState';

interface QueryParams {
    target?: 'login' | 'registration';
    headline?: string;
}

export function startAuthentication(
    requestedUrl?: string,
    { target = 'login', headline = 'adminPanel' }: QueryParams = {},
): void {
    const baseAuthorizeUrl = `${config.getItem('wagsUrl')}/v1/oauth/authorize`;

    const params = new URLSearchParams({
        target,
        state: generateAndStoreState<IStatePayload>({ requestedUrl }),
        redirect_uri: window.location.origin + ROUTES.OAUTH_CALLBACK,
        client_id: config.getItem('wagsClientId'),
        response_type: 'code',
    });

    if (headline) {
        params.append('headline', headline);
    }

    setTimeout(() => {
        window.location.assign(`${baseAuthorizeUrl}?${params}`);
    }, 0);
}
