import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Checkbox } from '@freenow/wave';
import { TableRowItem } from './Table';

const StyledTableRowItem = styled(TableRowItem)`
    flex-basis: 40px;
    flex-grow: 0;
`;

export const TableRowCheckboxItem = ({
    className = '',
    entity,
    checked = false,
    disabled = false,
    onChange,
    id = '',
}) => (
    <StyledTableRowItem className={className}>
        <Checkbox
            disabled={disabled}
            type="checkbox"
            checked={checked}
            onChange={(event) => onChange(entity, event.checked)}
            id={id}
        />
    </StyledTableRowItem>
);

TableRowCheckboxItem.propTypes = {
    className: PropTypes.string,
    entity: PropTypes.object.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
};
