import styled from 'styled-components';

import { getSemanticValue } from '@freenow/wave';

export const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid ${getSemanticValue('border-neutral-emphasized')};
    padding-bottom: 0.625rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;
