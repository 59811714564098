import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Logger from '@mytaxi/logging';
import { Box, Headline, Link, Logo } from '@freenow/wave';
import { Redirect } from 'react-router-dom';

import { CenteredLoadingIndicator } from '../../common/components';
import { trackLoginError } from '../../tracking/helpers/googleTagManager/trackEvents';
import { AuthenticationContext, IStatePayload } from '../context/AuthenticationContext';
import { startAuthentication } from '../util/startAuthentication';

export const OAuthCallback = () => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState<string>();
    const { handleOAuthCallback } = useContext(AuthenticationContext);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        handleOAuthCallback(searchParams.get('code') || '', searchParams.get('state') || '')
            .then((payload: IStatePayload) => {
                setRedirectUrl(payload.requestedUrl);
                setSuccess(true);
            })
            .catch((error) => {
                Logger.error('Failed to handle oauth callback', { error });
                setError(true);
            });
    }, [handleOAuthCallback]);

    if (error) {
        trackLoginError('Unknown');

        return (
            <Box p={6}>
                <Logo variant="business" monochrome />
                <Headline as="h3" mb={2} mt={5}>
                    <FormattedMessage id="login.errors.unknown" />
                </Headline>
                <Link onClick={() => startAuthentication(redirectUrl)}>
                    <FormattedMessage id="login.tryAgain" />
                </Link>
            </Box>
        );
    }

    if (success) {
        return <Redirect to={redirectUrl || '/'} />;
    }

    return <CenteredLoadingIndicator />;
};
