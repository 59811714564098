import React, { FC } from 'react';
import styled from 'styled-components';

import { getSemanticValue } from '@freenow/wave';

const Styling = styled.div<{ ellipsisText: boolean }>`
    flex-basis: 6.25rem;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    margin-right: 0.3125rem;
    color: ${getSemanticValue('foreground-primary')};
    overflow: ${(props) => (props.ellipsisText ? 'hidden' : 'visible')};
    text-overflow: ${(props) => (props.ellipsisText ? 'ellipsis' : 'clip')};
    white-space: ${(props) => (props.ellipsisText ? 'nowrap' : 'initial')};

    &:last-child {
        margin-right: 0;
    }
`;

interface Props {
    className?: string;
    dataTestId?: string;
    ellipsisText?: boolean;
    children: React.ReactNode;
}

export const TableRowItem: FC<Props> = ({ className = '', dataTestId = '', ellipsisText = true, children }) => {
    const title = typeof children === 'string' ? children : null;

    return (
        <Styling className={className} ellipsisText={ellipsisText} title={title} data-testid={dataTestId}>
            {children}
        </Styling>
    );
};
