import styled from 'styled-components';

export const ActionBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
`;

export const ActionBarLeft = styled.div`
    > *:not(:last-child) {
        margin-right: 0.625rem;
    }
`;

export const ActionBarRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > *:not(:last-child) {
        margin-right: 0.625rem;
    }
`;
