import useSWR, { SWRResponse } from 'swr';
import { AxiosError } from 'axios';

import { config } from '@mytaxi/config-store';

import { authorizedAxiosInstance } from '../../common/api/axios-instance';
import { logAxiosError } from '../../common/helpers/axiosLogger';
import { useCurrentBusinessAccountId } from '../../common/hooks/useCurrentBusinessAccountId';

export interface GroupPolicy {
    id: string;
    name: string;
}

export interface Group {
    id: string;
    name: string;
    description?: string;
    employeeCount: number;
    travelPolicies: GroupPolicy[];
    jobTicketPolicies: GroupPolicy[];
    benefitsPolicies: GroupPolicy[];
}

interface GroupListResponse {
    groups: Group[];
}

export function useGroupList(): SWRResponse<Group[], AxiosError> {
    const businessAccountId = useCurrentBusinessAccountId();

    const url = `${config.getItem('apgsUrl')}/v1/business-accounts/${businessAccountId}/groups`;

    return useSWR<Group[]>(url, () =>
        authorizedAxiosInstance
            .get<GroupListResponse>(url)
            .then((response) => response.data.groups)
            .catch((error) => {
                logAxiosError(`Failed to load groups for business account <${businessAccountId}>`, error);
                throw error;
            }),
    );
}
