import { useEffect, useState } from 'react';

import { fetchCountryList } from '../services/sign-up.service';
import { logAxiosError } from '../../common/helpers/axiosLogger';

const getFormattedCountryList = ({ countries }) => {
    const list = countries.map((country) => ({
        value: country.countryCode,
        text: country.name,
    }));

    const defaultCountry = countries.find((country) => country.default);

    return { list, default: defaultCountry?.countryCode || 'DE' };
};

interface Country {
    value: string;
    text: string;
}

interface CountriesState {
    countries: Country[];
    defaultCountry: string;
}

export const useSignUpCountries = (): CountriesState => {
    const [data, setData] = useState({
        countries: [],
        defaultCountry: 'DE',
    });

    useEffect(() => {
        fetchCountryList()
            .then((response) => {
                const formattedCountryList = getFormattedCountryList(response.data);
                setData({
                    countries: formattedCountryList.list,
                    defaultCountry: formattedCountryList.default,
                });
            })
            .catch((error) => {
                logAxiosError(`Exception caught fetching SSU country list: ${error.message}`, error);
            });
    }, []);

    return data;
};
