import React from 'react';
import styled from 'styled-components';

import { Text, MediaQueries, getSemanticValue } from '@freenow/wave';

import { useFormatMessage } from '../../common/hooks/useFormatMessage';

const determineBackgroundColor = (isActive, isDone) => {
    if (isActive) {
        return getSemanticValue('background-element-accent-emphasized');
    } else if (isDone) {
        return getSemanticValue('foreground-neutral-faded');
    } else {
        return getSemanticValue('foreground-primary');
    }
};

const StepContainer = styled.div`
    display: flex;
    align-items: center;
    ${MediaQueries.large} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const StepWrapper = styled.div`
    display: flex;

    & ${Text} {
        display: none;
    }

    ${MediaQueries.large} {
        & ${Text} {
            display: inline-block;
        }
    }
`;

const Step = styled.div`
    width: 32px;
    height: 32px;
    border: ${(props) => `2px solid ${determineBackgroundColor(props.isActive, props.isDone)}`};
    color: ${(props) => determineBackgroundColor(props.isActive, props.isDone)};
    font-size: 18px;
    font-weight: 600;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
`;

const Number = styled.p`
    margin-top: 1px;
`;

const StepSeparator = styled.div`
    flex-shrink: 1;
    min-width: 3.75rem;
    max-width: 7.75rem;
    height: 2px;
    background-color: ${getSemanticValue('foreground-primary')};

    ${MediaQueries.large} {
        max-width: 2px;
        min-width: 2px;
        height: 60px;
        margin-left: 15px;
    }
`;

const StyledText = styled(Text)`
    color: ${(props) => determineBackgroundColor(props.isActive, props.isDone)};
`;

const renderStep = (currentStepNumber) => (step, index) => {
    const isActive = index + 1 === currentStepNumber;
    const isDone = index + 1 < currentStepNumber;
    const stepItem = (
        <Step isActive={isActive} isDone={isDone}>
            <Number>{index + 1}</Number>
        </Step>
    );
    if (index !== 0) {
        return [
            <StepSeparator key={`separator-${index}`} />,
            <StepWrapper key={index}>
                {stepItem}
                <StyledText as="span" fontWeight="bold" ml={3} isActive={isActive} isDone={isDone}>
                    {step}
                </StyledText>
            </StepWrapper>,
        ];
    }
    return [
        <StepWrapper key={index}>
            {stepItem}
            <StyledText as="span" fontWeight="bold" ml={3} isActive={isActive} isDone={isDone}>
                {step}
            </StyledText>
        </StepWrapper>,
    ];
};

export const StepIndicator = ({ currentStepNumber }: { currentStepNumber: number }) => {
    const formatMessage = useFormatMessage();
    const steps = [
        formatMessage('signUp.stepIndicators.createAccount'),
        formatMessage('signUp.stepIndicators.createLoginCredentials'),
        formatMessage('signUp.stepIndicators.addPaymentMethod'),
    ];

    return <StepContainer>{steps.flatMap(renderStep(currentStepNumber))}</StepContainer>;
};
