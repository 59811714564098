import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { TextButton, Logo, getSemanticValue } from '@freenow/wave';

import { useFormatMessage } from '../../common/hooks/useFormatMessage';
import { DocumentTitle } from '../../common/components';
import { ROUTES } from '../../routing/constants/routes';
import { SignUpFooter } from './SignUpFooter';
import { LoginWrapper } from './LoginWrapper';

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 40px;
    border-bottom: 1px solid ${getSemanticValue('border-neutral-default')};
    background-color: ${getSemanticValue('background-page-default')};
`;

const Right = styled.div`
    margin-left: auto;
`;

const StickyFooter = styled(SignUpFooter)`
    z-index: 3;
`;

export const SignUpWrapper = ({ children, countryCode }: { children: React.ReactNode; countryCode?: string }) => {
    const formatMessage = useFormatMessage();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <LoginWrapper>
            <DocumentTitle title={formatMessage('signUp.documentTitle')} />
            <Header>
                <Link to={ROUTES.LOGIN}>
                    <Logo variant="business" monochrome />
                </Link>
                <Right>
                    <Link to={ROUTES.LOGIN}>
                        <TextButton>
                            <FormattedMessage id="signUp.linkToLogInLabel" />
                        </TextButton>
                    </Link>
                </Right>
            </Header>
            {children}
            <StickyFooter countryCode={countryCode} />
        </LoginWrapper>
    );
};
