import Logger from '@mytaxi/logging';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { authorizedAxiosInstance } from '../../common/api/axios-instance';
import { addAuthenticatedInterceptors } from '../../common/api/interceptor/addAuthenticatedInterceptors';
import LOCAL_STORAGE_KEYS from '../../common/constants/common.localStorageKeys';
import { resetBrowserSessionId } from '../../logging/initializeLogging';
import { updateDeviceFingerprint } from '../api/AdminPanelGatewayService';
import { deleteAccessTokens, getAccessTokenWithCode } from '../api/WebAuthenticationGatewayService';
import { useSyncWithLocalStorage } from '../hooks/useSyncWithLocalStorage';
import { getStoredState } from '../util/authenticationState';
import { AuthenticationContext, IAuthenticationContext, IStatePayload } from './AuthenticationContext';

export const AuthenticationContextProvider: FC = (props) => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || undefined);
    const [refreshToken, setRefreshToken] = useState(
        localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || undefined,
    );

    const handleOAuthCallback = useCallback((code: string, state: string): Promise<IStatePayload> => {
        return getAccessTokenWithCode(code).then((response) => {
            const statePayload = getStoredState<IStatePayload>(state);

            if (statePayload === null) {
                return Promise.reject(`No local state found for "${state}": authentication not possible.`);
            }

            setAccessToken(response.data.access_token);
            setRefreshToken(response.data.refresh_token);

            updateDeviceFingerprint(response.data.access_token);

            return Promise.resolve(statePayload);
        });
    }, []);

    const logout = useCallback((target?: string) => {
        deleteAccessTokens().finally(() => {
            setAccessToken(undefined);
            setRefreshToken(undefined);

            localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

            // clears logging metadata
            Logger.removeMetadata('businessAccountId');
            resetBrowserSessionId();

            if (target) {
                window.location.href = target;
            }
        });
    }, []);

    useSyncWithLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
    useSyncWithLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);

    useEffect(() => {
        const updateTokens = ({ accessToken, refreshToken }) => {
            setAccessToken(accessToken);
            setRefreshToken(refreshToken);
        };

        return addAuthenticatedInterceptors(authorizedAxiosInstance, { accessToken, refreshToken }, updateTokens);
    }, [accessToken, refreshToken]);

    const context: IAuthenticationContext = {
        isAuthenticated: !!(accessToken && refreshToken),
        handleOAuthCallback,
        logout,
    };

    return <AuthenticationContext.Provider {...props} value={context} />;
};
