import { createContext } from 'react';

export interface IStatePayload {
    requestedUrl?: string;
}

export interface IAuthenticationContext {
    isAuthenticated: boolean;
    logout: (redirectUrl?: string) => void;
    handleOAuthCallback: (code: string, state: string) => Promise<IStatePayload>;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
    isAuthenticated: false,
    logout: () => {
        throw Error('AuthenticationContextProvider not found');
    },
    handleOAuthCallback: () => {
        return Promise.reject('AuthenticationContextProvider not found');
    },
});
