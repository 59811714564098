import { config } from '@mytaxi/config-store';
import { axiosInstance } from '../../common/api/axios-instance';

import { getFingerprint } from '../util/getFingerprint';

export const updateDeviceFingerprint = async (accessToken: string) => {
    const url = `${config.getItem('apgsUrl')}/v1/login`;
    const deviceId = await getFingerprint();

    axiosInstance.post(
        url,
        { deviceId },
        {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        },
    );
};
