import TagManager from 'react-gtm-module';

export const trackPageView = (path) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'Pageview',
            url: path,
        },
    });
};
