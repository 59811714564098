import 'url-search-params-polyfill';

import * as React from 'react';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { initialize, automaticallyShowInAppMessages, openSession } from '@braze/web-sdk';
import Apptimize from '@apptimize/apptimize-web-sdk';

import Logger from '@mytaxi/logging';
import { config, writableConfig } from '@mytaxi/config-store';
import { TranslationProvider } from '@mytaxi/dynamic-translations';

import { GlobalStyles } from './GlobalStyles';
import { MainRouter } from './modules/routing/components/MainRouter';
import { CenteredLoadingIndicator, WrappedErrorPanel } from './modules/common/components';
import { LocaleContextProvider } from './modules/common/context/LocaleContext';
import { CookieConsentHandler } from './modules/common/containers/CookieConsentHandler.tsx';
import { trackPageView } from './modules/tracking/helpers/googleTagManager/trackPageView';
import { initializeLogging } from './modules/logging/initializeLogging';
import { RemoteSettingsContextProvider } from './modules/common/context/RemoteSettingsContext.tsx';
import { AuthenticationContextProvider } from './modules/authentication/context/AuthenticationContextProvider';

writableConfig.init(window.environmentConfig);

initializeLogging();

TagManager.initialize({
    gtmId: config.getItem('gtmId'),
});

// track initial page-view
trackPageView(window.location.pathname);

initialize(config.getItem('braze.projectId'), {
    enableHtmlInAppMessages: true,
    baseUrl: config.getItem('braze.baseUrl'),
    appVersion: process.env.REACT_APP_VERSION,
});
automaticallyShowInAppMessages();
openSession();

class App extends React.Component {
    static fetchTranslations(newLocale) {
        return require(`../public/translations/${newLocale}.json`);
    }

    state = {
        hasError: false,
        isApptimizeSdkReady: false,
    };

    componentDidMount() {
        let onApptimizeInitialized = () => this.setState({ isApptimizeSdkReady: true });
        Apptimize.setOnApptimizeInitializedCallback(onApptimizeInitialized);
        const configAttributes = {
            apptimize_region: 'eucs',
        };
        Apptimize.setup(config.getItem('apptimizeKey'), configAttributes);
    }

    componentDidCatch(error, info) {
        Sentry.withScope((scope) => {
            scope.setExtras(info);
            Sentry.captureException(error);
        });

        this.setState({
            hasError: true,
            error,
        });

        Logger.error(`Uncaught error in root error boundary: ${error && error.message}`, { error, info });
    }

    render() {
        if (!this.state.isApptimizeSdkReady) {
            return null;
        }

        if (this.state.hasError) {
            return (
                <>
                    <GlobalStyles />
                    <WrappedErrorPanel errorStack={this.state.error.stack} />
                </>
            );
        }
        const defaultLocale = 'en-GB';
        const availableLocales = config.getItem('availableLocales');

        return (
            <>
                <GlobalStyles />
                <TranslationProvider
                    defaultLocale={defaultLocale}
                    availableLocales={availableLocales}
                    fetchTranslation={App.fetchTranslations}
                    fallback={<CenteredLoadingIndicator />}
                >
                    <LocaleContextProvider>
                        <RemoteSettingsContextProvider>
                            <CookieConsentHandler />
                            <AuthenticationContextProvider>
                                <MainRouter />
                            </AuthenticationContextProvider>
                        </RemoteSettingsContextProvider>
                    </LocaleContextProvider>
                </TranslationProvider>
            </>
        );
    }
}

export default App;
