import React from 'react';
import styled from 'styled-components';

import { COOKIE_STATE, CookieBanner } from '@mytaxi/react-cookie-banner';

import { setConsentState } from '../../tracking/helpers/googleTagManager/setConsentState';
import { useFormatMessage } from '../hooks/useFormatMessage';

const Wrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10000;
`;

export const CookieConsentHandler = () => {
    const formatMessage = useFormatMessage();

    const handleCallback = (cookieState: COOKIE_STATE) => {
        setConsentState(cookieState === COOKIE_STATE.ACCEPTED);
    };

    return (
        <Wrapper>
            <CookieBanner
                informationText={formatMessage('cookieBanner.information')}
                confirmationText={formatMessage('cookieBanner.confirm')}
                link={{
                    url: formatMessage('cookieBanner.link.url'),
                    text: formatMessage('cookieBanner.link.text'),
                }}
                cancelText={formatMessage('cookieBanner.cancelText')}
                cookieStateCallback={handleCallback}
            />
        </Wrapper>
    );
};
