import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { NotificationList, NotificationManager } from '@mytaxi/react-notifications';

import { CenteredLoadingIndicator } from '../../common/components';
import { ROUTES } from '../constants/routes';
import { OAuthCallback } from '../../authentication/pages/OAuthCallback';
import { ChunkLoadErrorBoundary } from './ChunkLoadErrorBoundary';
import { PathTracking } from './PathTracking';
import { InvitationLinkLandingpage } from '../../joinCompany/containers/InvitationLinkLandingpage';
import { config } from '@mytaxi/config-store';
import { SignUpUnavailable } from '../../signUp/pages/SignUpUnavailable';

const SignUpRouter = lazy(() => import('../../signUp/pages/SignUpRouter'));
const NewSignUpRouter = lazy(() => import('../../signUp2/pages/SignUpRouter'));
const SecretSignUpRouter = lazy(() => import('../../signUp/pages/SecretSignUpRouter'));
const JoinCompanyRouter = lazy(() => import('../../joinCompany/containers/JoinCompanyRouter'));
const Login = lazy(() => import('../../authentication/pages/Login'));
const CreditCardRedirectPlaceholder = lazy(() => import('../../common/components/CreditCardRedirectPlaceholder'));
const LoggedInBootstrap = lazy(() => import('../../LoggedInBootstrap/LoggedInBootstrap'));
const BusinessProfileEmailVerificationPage = lazy(
    () => import('../../emailVerification/containers/BusinessProfileEmailVerificationPage'),
);

export const MainRouter = () => {
    const signUpComponent = () => {
        if (config.getItem('featureFlags.ssuImprovementsEnabled')) {
            return NewSignUpRouter;
        } else if (config.getItem('featureFlags.selfSignUpEnabled')) {
            return SignUpRouter;
        } else {
            return SignUpUnavailable;
        }
    };

    return (
        <ChunkLoadErrorBoundary>
            <BrowserRouter
                getUserConfirmation={() => {
                    /* Empty callback to block the default browser prompt */
                }}
            >
                <PathTracking />
                <NotificationManager>
                    <Suspense fallback={<CenteredLoadingIndicator />}>
                        <Switch>
                            <Route exact path={ROUTES.LOGIN} component={Login} />
                            <Route exact path={ROUTES.OAUTH_CALLBACK} component={OAuthCallback} />
                            <Route exact path={ROUTES.CREDIT_CARD_REDIRECT} component={CreditCardRedirectPlaceholder} />
                            <Route path={ROUTES.JOIN_COMPANY} component={JoinCompanyRouter} />
                            <Route path={ROUTES.INVITATION_LINK_LANDINGPAGE} component={InvitationLinkLandingpage} />
                            <Route
                                path={ROUTES.BUSINESS_PROFILE_EMAIL_VERIFICATION}
                                component={BusinessProfileEmailVerificationPage}
                            />
                            <Route path={ROUTES.SIGN_UP} component={signUpComponent()} />
                            <Route
                                path={ROUTES.SECRET_SIGN_UP}
                                component={
                                    config.getItem('featureFlags.newSelfSignUpEnabled')
                                        ? SecretSignUpRouter
                                        : SignUpUnavailable
                                }
                            />
                            <Route path="*" component={LoggedInBootstrap} />
                        </Switch>
                    </Suspense>
                    <NotificationList />
                </NotificationManager>
            </BrowserRouter>
        </ChunkLoadErrorBoundary>
    );
};
