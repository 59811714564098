import React from 'react';
import { Helmet } from 'react-helmet';

import { useFormatMessage } from '../hooks/useFormatMessage';

export const DocumentTitle = ({ title }: { title: string }) => {
    const formatMessage = useFormatMessage();
    const separator = formatMessage('common.documentTitleSeparator');
    const titleSuffix = formatMessage('common.documentTitleSuffix');

    return (
        <Helmet>
            <title>{`${title}${separator}${titleSuffix}`}</title>
        </Helmet>
    );
};
