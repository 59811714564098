import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Headline } from '@freenow/wave';

import { getBrowserLocale } from '../../common/helpers/translation';
import { SignUpWrapper } from '../components';

function getCountryCodeFromLocale() {
    const browserLocale = getBrowserLocale();
    let country;

    if (browserLocale.length === 2) {
        country = browserLocale;
    } else {
        country = browserLocale.substring(3, 5);
    }

    return country;
}

export const SignUpUnavailable = () => {
    const countryCode = getCountryCodeFromLocale();

    return (
        <SignUpWrapper countryCode={countryCode}>
            <Box display="flex" justifyContent="center" pt={8}>
                <Headline as="h3">
                    <FormattedMessage id="signUp.pageUnavailable" />
                </Headline>
            </Box>
        </SignUpWrapper>
    );
};
