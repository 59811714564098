import React, { useEffect, useState } from 'react';

import { config } from '@mytaxi/config-store';

import { authorizedAxiosInstance } from '../../common/api/axios-instance';
import { CenteredLoadingIndicator } from '../../common/components';
import { useCurrentBusinessAccountId } from '../../common/hooks/useCurrentBusinessAccountId';

export interface PremiumFeatures {
    dashboardEnabled: boolean;
    employeeListDownloadEnabled: boolean;
    invoiceScheduleTypeChangeAllowed: boolean;
    referenceListManagementEnabled: boolean;
    referenceMandatoryChangeAllowed: boolean;
    costCenterManagementEnabled: boolean;
    bookerCostCenterForGuestBookingSettingEnabled: boolean;
    sendSmsToBookerChangeAllowed: boolean;
    travelPoliciesEnabled: boolean;
    hrToolsIntegrationEnabled: boolean;
    notesManagementEnabled: boolean;
}

const defaultValue = {
    dashboardEnabled: false,
    employeeListDownloadEnabled: false,
    invoiceScheduleTypeChangeAllowed: false,
    referenceListManagementEnabled: false,
    referenceMandatoryChangeAllowed: false,
    costCenterManagementEnabled: false,
    bookerCostCenterForGuestBookingSettingEnabled: false,
    sendSmsToBookerChangeAllowed: false,
    travelPoliciesEnabled: false,
    hrToolsIntegrationEnabled: false,
    notesManagementEnabled: false,
};

const PremiumFeaturesContext = React.createContext<PremiumFeatures>(defaultValue);

const PremiumFeaturesContextProvider = (props: { children: React.ReactNode }) => {
    const businessAccountId = useCurrentBusinessAccountId();
    const [loading, setLoading] = useState<boolean>(true);
    const [premiumFeatures, setPremiumFeatures] = useState<PremiumFeatures>(defaultValue);

    useEffect(() => {
        authorizedAxiosInstance
            .get(`${config.getItem('apgsUrl')}/v1/business-accounts/${businessAccountId}/premium-features`)
            .then((response) => {
                if (response.data) {
                    setPremiumFeatures(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [businessAccountId]);

    if (loading) {
        return <CenteredLoadingIndicator />;
    }

    return <PremiumFeaturesContext.Provider {...props} value={premiumFeatures} />;
};

export { PremiumFeaturesContext, PremiumFeaturesContextProvider };
