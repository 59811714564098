import React, { Fragment, useEffect } from 'react';

import styled from 'styled-components';
import { getSemanticValue } from '@freenow/wave';

const Background = styled.div`
    position: fixed;
    // needs to overlay footer
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
`;

const Content = styled.div`
    position: fixed;
    // needs to overlay footer and Background
    z-index: 12;
    top: 0;
    right: 0;
    height: 100%;
    width: 510px;
    padding: 24px;
    overflow-y: auto;
    background: ${getSemanticValue('background-page-default')};
    box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.04);
`;

const KEYCODE_ESC = 27;
export const SideBarPopup = ({ children, onHide }) => {
    useEffect(() => {
        const handleKeyUp = (e) => {
            if (e.key === 'Escape' || e.keyCode === KEYCODE_ESC) {
                onHide();
            }
        };

        document.addEventListener('keydown', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyUp);
        };
    }, [onHide]);

    return (
        <Fragment>
            <Background onClick={onHide} />

            <Content>{children}</Content>
        </Fragment>
    );
};
