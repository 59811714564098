import { createGlobalStyle } from 'styled-components';
import { getSemanticValue } from '@freenow/wave';

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        border: 0;
        box-sizing: inherit;
    }

    body,
    html,
    .js_app-root,
    .layout {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        font-size: 16px;

        font-family: 'Open Sans', Helvetica, sans-serif;
        box-sizing: border-box;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    #root>.wave {
        background-color: ${getSemanticValue('background-page-default')} !important;
        min-height: 100vh;
    }
`;
