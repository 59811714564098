import React, { useCallback, useEffect, useState } from 'react';

import { Text, Box, Pagination } from '@freenow/wave';

type Props<T> = {
    list: T[];
    onChange: (list: T[]) => void;
    pageSize: number;
};

export const TablePageNavigation = <T extends any>({ list, onChange, pageSize }: Props<T>) => {
    const [pageIndex, setPageIndex] = useState(0);

    const getListSliceMatchingPage = useCallback(
        (pageIndex) => list.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
        [list, pageSize],
    );

    useEffect(() => setPageIndex(0), [list]);
    useEffect(
        () => onChange(getListSliceMatchingPage(pageIndex)),
        [list, pageIndex, onChange, getListSliceMatchingPage],
    );

    const index = pageIndex + 1;
    // || 1 used to fix Pagination UI
    const lastPage = Math.ceil(list.length / pageSize) || 1;

    if (list.length <= pageSize) {
        return null;
    }

    return (
        <Box mt={4}>
            <Pagination
                value={index}
                pageSize={pageSize}
                // || 1 used to fix Pagination UI
                totalItems={list.length || 1}
                onNextPage={() => setPageIndex((current) => current + 1)}
                onPrevPage={() => setPageIndex((current) => current - 1)}
                onSkipForward={() => setPageIndex(lastPage - 1)}
                onSkipBackward={() => setPageIndex(0)}
                label={
                    <Text as="b" fontWeight="semibold" secondary>
                        {index} / {lastPage}
                    </Text>
                }
            />
        </Box>
    );
};
