import React, { useEffect, useMemo, useRef } from 'react';
import * as googleLibphonenumber from 'google-libphonenumber';
import { Field, useField, useFormikContext } from 'formik';

import { Input, Select, Checkbox, PhoneInput, PHONE_AREA_CODE_COUNTRIES } from '@freenow/wave';

import { areStatementsAllowedForCountry } from '../../featureFlags/helpers/features';
import { InputRow } from './InputRow';
import { InvoiceCreateAccountForm } from './InvoiceCreateAccountForm';
import { useSignUpCountries } from '../hook/useSignUpCountries';
import { useFormatMessage } from '../../common/hooks/useFormatMessage';
import { Draft } from '../schema/createAccountForm';

const getCountryAreaCode = (countryCode) => {
    const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance();
    const areaCode = phoneUtil.getMetadataForRegion(countryCode).getCountryCode();
    return `+${areaCode}`;
};

export const BasicCreateAccountForm = ({ settings, setCountryCode }) => {
    const formatMessage = useFormatMessage();
    const { countries, defaultCountry } = useSignUpCountries();
    const { values, setValues, setFieldValue } = useFormikContext<Draft>();

    const [areaCodeField, , areaCodeHelpers] = useField('companyPhoneAreaCode');
    const [phoneNumberField, phoneNumberMeta, phoneNumberHelpers] = useField('companyPhoneNumber');

    const businessAccountSizes = useMemo(() => {
        return [
            {
                id: '1 - 20',
                text: formatMessage('signUp.addCompanyAddress.accountSizeSmall'),
            },
            {
                id: '21 - 250',
                text: formatMessage('signUp.addCompanyAddress.accountSizeMedium'),
            },
            {
                id: '251 - 500',
                text: formatMessage('signUp.addCompanyAddress.accountSizeLarge'),
            },
            {
                id: '501 - 1000',
                text: formatMessage('signUp.addCompanyAddress.accountSizeXLarge'),
            },
            {
                id: '1001 - 5000',
                text: formatMessage('signUp.addCompanyAddress.accountSizeXXLarge'),
            },
            {
                id: '5000+',
                text: formatMessage('signUp.addCompanyAddress.accountSizeXXXLarge'),
            },
        ];
    }, [formatMessage]);

    const prevCountryCode = useRef<string>();

    useEffect(() => {
        setFieldValue('countryCode', defaultCountry);
    }, [defaultCountry, setFieldValue]);

    useEffect(() => {
        if (values.countryCode !== prevCountryCode.current) {
            const update = {
                ...values,
                acceptGtc: false,
                taxId: '',
                vatId: '',
                codiceUnivocoId: '',
                pecOfficialEmailAddress: '',
            };

            if (!values.invoiceAddressLine && !values.invoicePostalCode && !values.invoiceCityName) {
                update.invoiceCountryCode = values.countryCode;
            }

            if (!areStatementsAllowedForCountry(values.countryCode)) {
                update.differentInvoiceAddress = false;
            }
            if (!values.companyPhoneNumber) {
                update.companyPhoneAreaCode = getCountryAreaCode(values.countryCode);
            }
            setValues({ ...values, ...update });
            setCountryCode(values.countryCode);
        }
        prevCountryCode.current = values.countryCode;
    }, [values, prevCountryCode, setCountryCode, setValues]);

    useEffect(() => {
        if (!values.differentInvoiceAddress) {
            setFieldValue('invoiceAddressLine', '');
            setFieldValue('invoicePostalCode', '');
            setFieldValue('invoiceCityName', '');
        }
    }, [values.differentInvoiceAddress, setFieldValue]);

    const renderVatIdField = () => {
        if (settings && settings.vatIdRequired) {
            const vatIdExamples = {
                DE: formatMessage('signUp.addCompanyAddress.vatIdExample.de', {}, ' '),
                AT: formatMessage('signUp.addCompanyAddress.vatIdExample.at', {}, ' '),
                FR: formatMessage('signUp.addCompanyAddress.vatIdExample.fr', {}, ' '),
                IE: formatMessage('signUp.addCompanyAddress.vatIdExample.ie', {}, ' '),
                IT: formatMessage('signUp.addCompanyAddress.vatIdExample.it', {}, ' '),
                PL: formatMessage('signUp.addCompanyAddress.vatIdExample.pl', {}, ' '),
                ES: formatMessage('signUp.addCompanyAddress.vatIdExample.es', {}, ' '),
                GB: formatMessage('signUp.addCompanyAddress.vatIdExample.gb', {}, ' '),
            };

            return (
                <Field name="vatId">
                    {({ field, meta }) => (
                        <Input
                            label={`${formatMessage('signUp.addCompanyAddress.vatId')} ${
                                vatIdExamples[values.countryCode] ? vatIdExamples[values.countryCode] : ''
                            }`}
                            {...field}
                            error={meta.touched && meta.error}
                            required={settings.vatIdRequired}
                            width={1}
                            mb={2}
                            data-testid="add-company-address-vatid"
                        />
                    )}
                </Field>
            );
        }

        return null;
    };

    const renderTaxIdField = () => {
        if (settings && settings.taxIdRequired) {
            const taxIdExamples = {
                DE: formatMessage('signUp.addCompanyAddress.taxIdExample.de', {}, ' '),
                AT: formatMessage('signUp.addCompanyAddress.taxIdExample.at', {}, ' '),
                FR: formatMessage('signUp.addCompanyAddress.taxIdExample.fr', {}, ' '),
                IE: formatMessage('signUp.addCompanyAddress.taxIdExample.ie', {}, ' '),
                IT: formatMessage('signUp.addCompanyAddress.taxIdExample.it', {}, ' '),
                PL: formatMessage('signUp.addCompanyAddress.taxIdExample.pl', {}, ' '),
                ES: formatMessage('signUp.addCompanyAddress.taxIdExample.es', {}, ' '),
                GB: formatMessage('signUp.addCompanyAddress.taxIdExample.gb', {}, ' '),
            };

            return (
                <Field name="taxId">
                    {({ field, meta }) => (
                        <Input
                            label={`${formatMessage('signUp.addCompanyAddress.taxId')} ${
                                taxIdExamples[values.countryCode] ? taxIdExamples[values.countryCode] : ''
                            }`}
                            {...field}
                            error={meta.touched && meta.error}
                            required={settings.taxIdRequired}
                            width={1}
                            mb={2}
                            data-testid="add-company-address-taxid"
                        />
                    )}
                </Field>
            );
        }

        return null;
    };

    const renderItalianFields = () => {
        if (values.countryCode !== 'IT') {
            return null;
        }

        return (
            <>
                <Field name="pecOfficialEmailAddress">
                    {({ field, meta }) => (
                        <Input
                            label={formatMessage('signUp.addCompanyAddress.pecEmailAddress')}
                            {...field}
                            error={meta.touched && meta.error}
                            required
                            width={1}
                            mb={2}
                        />
                    )}
                </Field>
                <Field name="codiceUnivocoId">
                    {({ field, meta }) => (
                        <Input
                            label={formatMessage('signUp.addCompanyAddress.codiceUnivoco')}
                            {...field}
                            error={meta.touched && meta.error}
                            required
                            width={1}
                            mb={2}
                        />
                    )}
                </Field>
            </>
        );
    };

    return (
        <>
            <Field name="businessAccountSizeRange">
                {({ field, meta }) => (
                    <Select
                        label={formatMessage('signUp.addCompanyAddress.accountSizeLabel')}
                        {...field}
                        error={meta.touched && meta.error}
                        width="100%"
                        mb={2}
                        data-testid="company-size"
                    >
                        <option value="" disabled>
                            {formatMessage('signUp.addCompanyAddress.accountSizePlaceholder')}
                        </option>
                        {businessAccountSizes.map((it) => (
                            <option key={it.id} value={it.id}>
                                {it.text}
                            </option>
                        ))}
                    </Select>
                )}
            </Field>
            <Field name="businessAccountName">
                {({ field, meta }) => (
                    <Input
                        label={formatMessage('signUp.createAccount.businessName')}
                        {...field}
                        error={meta.touched && meta.error}
                        required
                        width="100%"
                        mb={2}
                        data-testid="business-name-input"
                    />
                )}
            </Field>
            <Field name="businessAccountEmailAddress">
                {({ field, meta }) => (
                    <Input
                        label={formatMessage('signUp.createAccount.businessEmail')}
                        {...field}
                        error={meta.touched && meta.error}
                        required
                        width="100%"
                        mb={2}
                        data-testid="business-email-address-input"
                    />
                )}
            </Field>
            <Field name="addressLine">
                {({ field, meta }) => (
                    <Input
                        label={formatMessage('signUp.addCompanyAddress.streetName')}
                        {...field}
                        error={meta.touched && meta.error}
                        width={1}
                        data-testid="add-company-address-streetname"
                        mb={2}
                    />
                )}
            </Field>
            <InputRow>
                <Field name="postalCode">
                    {({ field, meta }) => (
                        <Input
                            label={formatMessage('signUp.addCompanyAddress.zipCode')}
                            {...field}
                            error={meta.touched && meta.error}
                            width={0.33}
                            data-testid="add-company-address-zipcode"
                        />
                    )}
                </Field>
                <Field name="cityName">
                    {({ field, meta }) => (
                        <Input
                            label={formatMessage('signUp.addCompanyAddress.city')}
                            {...field}
                            error={meta.touched && meta.error}
                            width={0.67}
                            ml={2}
                            data-testid="add-company-address-cityname"
                        />
                    )}
                </Field>
            </InputRow>
            <Field name="countryCode">
                {({ field, meta }) => (
                    <Select
                        label={formatMessage('signUp.createAccount.country')}
                        {...field}
                        error={meta.touched && meta.error}
                        width="100%"
                        mb={2}
                        data-testid="country-select"
                    >
                        {countries.map((it) => (
                            <option key={it.value} value={it.value}>
                                {it.text}
                            </option>
                        ))}
                    </Select>
                )}
            </Field>
            {renderItalianFields()}
            {renderVatIdField()}
            {renderTaxIdField()}

            <InputRow>
                <PhoneInput
                    id="phone-number"
                    width="100%"
                    country={PHONE_AREA_CODE_COUNTRIES.find(
                        (countryCode) => countryCode.dialCode === areaCodeField.value,
                    )}
                    label={formatMessage('signUp.addCompanyAddress.companyPhoneNumber')}
                    text={phoneNumberField.value}
                    onTextChange={(e) => {
                        phoneNumberHelpers.setValue(e.target.value);
                    }}
                    onCountryChange={(selectedCode) => areaCodeHelpers.setValue(selectedCode?.dialCode)}
                    error={phoneNumberMeta.touched && phoneNumberMeta.error !== undefined}
                    inputProps={{
                        required: true,
                        onBlur: () => phoneNumberHelpers.setTouched(true),
                    }}
                />
            </InputRow>

            {areStatementsAllowedForCountry(values.countryCode) && (
                <Field name="differentInvoiceAddress">
                    {({ field }) => (
                        <Checkbox
                            label={formatMessage('signUp.addCompanyAddress.hasDifferentInvoiceAddress')}
                            {...field}
                            checked={field.value}
                            mb={2}
                            mt={1}
                        />
                    )}
                </Field>
            )}
            <InvoiceCreateAccountForm countries={countries} />
        </>
    );
};
