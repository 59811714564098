import React, { useState } from 'react';
import styled from 'styled-components';

import { getSemanticValue, InfoCircleIcon } from '@freenow/wave';

const Wrapper = styled.span`
    position: relative;
    text-indent: 0;
    margin-left: 10px;

    & svg {
        vertical-align: middle;
    }
`;

const Content = styled.div`
    position: absolute;
    padding: 30px;
    background-color: ${getSemanticValue('background-page-default')};
    color: ${getSemanticValue('foreground-primary')};
    z-index: 2;
    width: 350px;

    & h2 {
        font-weight: 100;
        margin-bottom: 15px;
    }

    & h3 {
        margin-bottom: 5px;
        color: ${getSemanticValue('foreground-primary')};
    }

    &.info-bubble--left {
        right: 20px;
        top: 0;
    }

    &.info-bubble--right {
        left: 25px;
        top: 0;
    }

    &.info-bubble--top {
        bottom: 30px;
    }

    &.info-bubble--bottom {
        top: 25px;
    }
`;

interface Props {
    position?: string;
    children: React.ReactNode;
}

export const IconWithInfoBubble: React.FC<Props> = ({ children, position = 'left' }) => {
    const [visible, setVisible] = useState(false);
    const hide = () => setVisible(false);
    const show = () => setVisible(true);

    return (
        <Wrapper onMouseEnter={show} onMouseLeave={hide}>
            <InfoCircleIcon size={20} />
            {visible ? <Content className={`info-bubble--${position}`}>{children}</Content> : null}
        </Wrapper>
    );
};
