import React from 'react';
import { Field, useFormikContext } from 'formik';

import { Input, Select } from '@freenow/wave';

import { InputRow } from './InputRow';
import { useFormatMessage } from '../../common/hooks/useFormatMessage';

export const InvoiceCreateAccountForm = ({ countries }) => {
    const formatMessage = useFormatMessage();
    const { values } = useFormikContext();

    if (!values.differentInvoiceAddress) {
        return null;
    }

    return (
        <>
            <Field name="invoiceAddressLine">
                {({ field, meta }) => (
                    <Input
                        label={formatMessage('signUp.addCompanyAddress.streetName')}
                        {...field}
                        error={meta.touched && meta.error}
                        width={1}
                        mb={2}
                    />
                )}
            </Field>
            <InputRow>
                <Field name="invoicePostalCode">
                    {({ field, meta }) => (
                        <Input
                            label={formatMessage('signUp.addCompanyAddress.zipCode')}
                            {...field}
                            error={meta.touched && meta.error}
                            width={0.33}
                        />
                    )}
                </Field>
                <Field name="invoiceCityName">
                    {({ field, meta }) => (
                        <Input
                            label={formatMessage('signUp.addCompanyAddress.city')}
                            {...field}
                            error={meta.touched && meta.error}
                            width={0.67}
                            ml={2}
                        />
                    )}
                </Field>
            </InputRow>
            <Field name="invoiceCountryCode">
                {({ field, meta }) => (
                    <Select {...field} error={meta.touched && meta.error} width="100%" mb={3}>
                        <option value="" disabled hidden>
                            {formatMessage('signUp.addCompanyAddress.countryPlaceholder')}
                        </option>
                        {countries.map((country) => (
                            <option key={country.value} value={country.value}>
                                {country.text}
                            </option>
                        ))}
                    </Select>
                )}
            </Field>
        </>
    );
};
