import { config } from '@mytaxi/config-store';

import { axiosInstance } from '../../common/api/axios-instance';

interface InvitationLinkDetailsResponse {
    businessAccountName: string;
}

export const getInvitationLinkDetails = (invitationLinkToken: string) => {
    const url = `${config.getItem('apgsUrl')}/v1/invitation-link/${invitationLinkToken}`;

    return axiosInstance.get<InvitationLinkDetailsResponse>(url);
};

interface VerifyAndGetInviteEmailAddressResponse {
    businessEmailAddress: string;
}

export const verifyDomainAndTriggerInviteEmail = (invitationLinkToken: string, businessEmailAddress: string) => {
    const url = `${config.getItem('apgsUrl')}/v1/invitation-link/invite`;

    const requestBody = {
        invitationLinkToken,
        businessEmailAddress,
    };

    return axiosInstance.post<VerifyAndGetInviteEmailAddressResponse>(url, requestBody);
};
