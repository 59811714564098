import React, { createContext, useEffect, useState } from 'react';
import storage from 'local-storage-fallback';
import { useHistory } from 'react-router';

import LOCAL_STORAGE_KEYS from '../../common/constants/common.localStorageKeys';
import { getQueryString } from '../../common/helpers/getQueryString';

interface BusinessAccountDraft {
    businessAccountId?: number;
    draftId?: string;
    countryCode?: string;
}

interface ISignUpContextContext {
    signUpSteps: string[];
    allowSignUpStep: (step: string) => void;
    resetSignUpSteps: () => void;
    businessAccountDraft: BusinessAccountDraft;
    updateBusinessAccountDraft: (draft: Partial<BusinessAccountDraft>) => void;
}

export const SignUpContext = createContext<ISignUpContextContext>({
    signUpSteps: [],
    allowSignUpStep: () => {
        throw Error('SignUpContext is not initialized yet.');
    },
    resetSignUpSteps: () => {
        throw Error('SignUpContext is not initialized yet.');
    },
    businessAccountDraft: {},
    updateBusinessAccountDraft: () => {
        throw Error('SignUpContext is not initialized yet.');
    },
});

export const getSignUpStepsFromStore = () => {
    const storedSignUpSteps = storage.getItem(LOCAL_STORAGE_KEYS.ALLOWED_SIGN_UP_STEPS);

    if (storedSignUpSteps) {
        return JSON.parse(storedSignUpSteps);
    }

    return [];
};

export const getBusinessAccountDraftFromStore = () => {
    const storedLocalBusinessAccountDraft = storage.getItem(LOCAL_STORAGE_KEYS.STORE_LOCAL_BUSINESS_ACCOUNT_DRAFT);

    if (storedLocalBusinessAccountDraft) {
        return JSON.parse(storedLocalBusinessAccountDraft);
    }

    return {
        draftId: undefined,
        countryCode: undefined,
        businessAccountId: undefined,
    };
};

export const SignUpContextProvider = ({ children }: { children: React.ReactNode }) => {
    const history = useHistory();
    const [signUpSteps, setSignUpSteps] = useState<string[]>(getSignUpStepsFromStore());
    const [businessAccountDraft, setBusinessAccountDraft] = useState(getBusinessAccountDraftFromStore());

    useEffect(() => {
        const storedTrackingParams = storage.getItem(LOCAL_STORAGE_KEYS.SIGN_UP_TRACKING_PARAMS);
        if (!storedTrackingParams) {
            const trackingParams = getQueryString();
            if (trackingParams) {
                storage.setItem(LOCAL_STORAGE_KEYS.SIGN_UP_TRACKING_PARAMS, trackingParams);
            }
        }
    }, []);

    const allowSignUpStep = (step: string) => {
        const updatedSignUpSteps = [...signUpSteps, step];
        storage.setItem(LOCAL_STORAGE_KEYS.ALLOWED_SIGN_UP_STEPS, JSON.stringify([...updatedSignUpSteps]));
        setSignUpSteps([...updatedSignUpSteps]);
        history.push(step);
    };

    const resetSignUpSteps = () => {
        storage.setItem(LOCAL_STORAGE_KEYS.ALLOWED_SIGN_UP_STEPS, JSON.stringify([]));
        setSignUpSteps([]);
    };

    const updateBusinessAccountDraft = (draft) => {
        const updatedDraft = {
            ...businessAccountDraft,
            ...draft,
        };

        storage.setItem(LOCAL_STORAGE_KEYS.STORE_LOCAL_BUSINESS_ACCOUNT_DRAFT, JSON.stringify(updatedDraft));
        setBusinessAccountDraft(updatedDraft);
    };

    return (
        <SignUpContext.Provider
            value={{ signUpSteps, allowSignUpStep, resetSignUpSteps, businessAccountDraft, updateBusinessAccountDraft }}
        >
            {children}
        </SignUpContext.Provider>
    );
};
