import styled from 'styled-components';

import { getSemanticValue } from '@freenow/wave';

export const FOOTER_HEIGHT = '65px';

export const FormFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    margin-left: 240px;
    left: 0;
    right: 0;
    bottom: ${FOOTER_HEIGHT};
    height: ${FOOTER_HEIGHT};
    z-index: 1;
    padding: 0 2.5rem;
    border-top: 1px solid ${getSemanticValue('border-neutral-default')};
`;
