import React from 'react';
import styled from 'styled-components';

import { Box, getSemanticValue } from '@freenow/wave';

const StyledBox = styled(Box)`
    background-color: ${getSemanticValue('background-page-default')};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
`;

export const WhiteContentBox = ({ children, ...props }) => (
    <StyledBox {...props} p={3}>
        {children}
    </StyledBox>
);
