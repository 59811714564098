import React from 'react';
import styled from 'styled-components';

import { getSemanticValue, Headline, Text } from '@freenow/wave';

import { ReactComponent as ToArrow } from '../../../images/icons/icon-to-arrow.svg';

const BulletPointWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 64px;
`;

const StyledToArrow = styled(ToArrow)`
    margin-right: 16px;
    vertical-align: middle;
`;

export const InfoBulletPoint = ({ headline, description }: { headline: string; description: string }) => (
    <BulletPointWrapper>
        <div>
            <StyledToArrow color={getSemanticValue('foreground-primary')} />
        </div>
        <div>
            <Headline as="h4" mb={1}>
                {headline}
            </Headline>
            <Text as="p">{description}</Text>
        </div>
    </BulletPointWrapper>
);
