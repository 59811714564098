import React from 'react';

import { Headline, Text, Box } from '@freenow/wave';

interface Props {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
}

export const PageHeader = ({ title, children, subtitle = '' }: Props) => (
    <Box display="flex" justifyContent="space-between" mb={3} data-testid="page-header">
        <Box display="flex" flexDirection="column">
            <Headline as="h2">{title}</Headline>
            {subtitle ? (
                <Text as="p" fontSize={1} mt={1}>
                    {subtitle}
                </Text>
            ) : null}
        </Box>
        {children ? (
            <Box ml={2} minWidth="200px" display="flex" justifyContent="flex-end">
                {children}
            </Box>
        ) : null}
    </Box>
);
