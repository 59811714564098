import React from 'react';
import styled from 'styled-components';

import { Box } from '@freenow/wave';

import { ErrorIllustration } from './ErrorIllustration';

const CenteredBlockText = styled(Box)`
    text-align: center;
`;

export const ErrorScreen = ({ children }: { children: React.ReactNode }) => (
    <>
        <Box display="flex" justifyContent="center" mb={4}>
            <ErrorIllustration />
        </Box>
        <CenteredBlockText weight={600}>{children}</CenteredBlockText>
    </>
);
