import { Modal, Headline, Button, Text } from '@freenow/wave';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
    onHide: () => void;
    onClick: () => void;
    heading: string;
    okText: string;
    cancelText?: string;
    subtitle: string;
}
export const NotificationsModal = ({ onHide, onClick, heading, okText, cancelText, subtitle }: Props) => {
    return (
        <Modal onClose={onHide}>
            {(dismiss) => (
                <>
                    <Headline as="h3">
                        <FormattedMessage id={heading} />
                    </Headline>
                    <Text as="p" mt={2} mb={4} secondary>
                        <FormattedMessage id={subtitle} />
                    </Text>

                    <Button onClick={onClick}>
                        <FormattedMessage id={okText} />
                    </Button>
                    <Button variant="secondary" onClick={dismiss} ml={1}>
                        <FormattedMessage id={cancelText || 'common.cancel'} />
                    </Button>
                </>
            )}
        </Modal>
    );
};
