import defaultAxios, { AxiosInstance } from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { addXMytApplicationHeaderInterceptor } from './interceptor/addXMytApplicationHeaderInterceptor';

export const axiosInstance = addXMytApplicationHeaderInterceptor(defaultAxios.create());

// the correct authorization headers are added in the AuthenticationContextProvider
export const authorizedAxiosInstance: AxiosInstance = defaultAxios.create();

export const useAuthorizedAxios = makeUseAxios({ axios: authorizedAxiosInstance });
