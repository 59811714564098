import { config } from '@mytaxi/config-store';
import { AxiosPromise } from 'axios';
import { authorizedAxiosInstance, axiosInstance } from '../../common/api/axios-instance';
import { ROUTES } from '../../routing/constants/routes';

interface TokenResponse {
    access_token: string;
    refresh_token: string;
}

export function getAccessTokenWithCode(code: string): AxiosPromise<TokenResponse> {
    const url = `${config.getItem('wagsUrl')}/v1/oauth/token`;

    const params = new URLSearchParams({
        client_id: config.getItem('wagsClientId'),
        grant_type: 'authorization_code',
        code,
        redirect_uri: window.location.origin + ROUTES.OAUTH_CALLBACK,
    });

    const requestConfig = {
        auth: {
            username: config.getItem('wagsClientId'),
            password: '',
        },
    };

    return axiosInstance.post(url, params.toString(), requestConfig);
}

export function deleteAccessTokens(): AxiosPromise {
    return authorizedAxiosInstance.delete(`${config.getItem('wagsUrl')}/v1/logout`);
}
