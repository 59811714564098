import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { RouteChildrenProps, useHistory } from 'react-router';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
    Box,
    Button,
    Card,
    EnvelopeIcon,
    getSemanticValue,
    Headline,
    InlineSpinner,
    Input,
    Logo,
    Spaces,
    Text,
} from '@freenow/wave';
import { Level, useNotify } from '@mytaxi/react-notifications';

import inviteLinkImage from '../../../images/invitationLinkHeader.png';
import { useFormatMessage } from '../../common/hooks/useFormatMessage';
import { logAxiosError } from '../../common/helpers/axiosLogger';
import { ROUTES } from '../../routing/constants/routes';
import { EMAIL_VALIDATION_REGEX } from '../../employees/constants/employee.constants';
import { getInvitationLinkDetails, verifyDomainAndTriggerInviteEmail } from '../api/invitationLink.api';
import Logger from '@mytaxi/logging';

const UnorderedList = styled.ul`
    list-style: none;
    padding-left: ${Spaces[2]};
`;

const ListItem = styled.li`
    margin-top: ${Spaces[3]};

    &::before {
        content: '\\2022';
        color: ${getSemanticValue('foreground-primary')};
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
`;

export const InvitationLinkLandingpage = ({ match }: RouteChildrenProps<{ invitationLinkToken?: string }>) => {
    const history = useHistory();
    const formatMessage = useFormatMessage();
    const createNotification = useNotify();
    const [businessAccountName, setBusinessAccountName] = useState<string>();
    const [workEmail, setWorkEmail] = useState<string>('');
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [inviteEmailResponse, setInviteEmailResponse] = useState<string>('');

    useEffect(() => {
        const fetchInvitationLinkDetails = async () => {
            const invitationLinkToken = match?.params.invitationLinkToken;
            try {
                const response = await getInvitationLinkDetails(invitationLinkToken);
                setBusinessAccountName(response.data.businessAccountName);
            } catch (error) {
                const e = error as AxiosError;
                logAxiosError(
                    `There was an error fetching invitation link Details for token: ${invitationLinkToken}`,
                    e,
                );
                history.push(ROUTES.ROOT);
            }
        };

        fetchInvitationLinkDetails();
    }, [match, history]);

    const submitWorkEmail = async () => {
        setLoading(true);
        const invitationLinkToken = match?.params.invitationLinkToken;
        try {
            const response = await verifyDomainAndTriggerInviteEmail(invitationLinkToken, workEmail);
            setInviteEmailResponse(response.data.businessEmailAddress);
            setEmailSent(true);
            setLoading(false);
        } catch (error) {
            const e = error as AxiosError;
            Logger.warn(
                `There was an error submitting work email (${workEmail}) for token: ${invitationLinkToken}: ${e.message}`,
                { error: e },
            );
            const message = formatMessage('invitationLink.landingpage.submitErrorNotification');
            createNotification(message, Level.ERROR);
            setLoading(false);
            setEmailSent(false);
        }
    };

    if (businessAccountName) {
        const isValidEmail = !!workEmail.trim().match(EMAIL_VALIDATION_REGEX);

        const bulletPoints = [
            {
                keyword: formatMessage('invitationLink.landingpage.bulletPoint1.keyword', {}, ' '),
                text: formatMessage('invitationLink.landingpage.bulletPoint1.text', {}, ' '),
            },
            {
                keyword: formatMessage('invitationLink.landingpage.bulletPoint2.keyword', {}, ' '),
                text: formatMessage('invitationLink.landingpage.bulletPoint2.text', {}, ' '),
            },
            {
                keyword: formatMessage('invitationLink.landingpage.bulletPoint3.keyword', {}, ' '),
                text: formatMessage('invitationLink.landingpage.bulletPoint3.text', {}, ' '),
            },
            {
                keyword: formatMessage('invitationLink.landingpage.bulletPoint4.keyword', {}, ' '),
                text: formatMessage('invitationLink.landingpage.bulletPoint4.text', {}, ' '),
            },
            {
                keyword: formatMessage('invitationLink.landingpage.bulletPoint5.keyword', {}, ' '),
                text: formatMessage('invitationLink.landingpage.bulletPoint5.text', {}, ' '),
            },
        ];

        return (
            <Box pb={5} pt={{ _: 0, large: 4 }}>
                <Box ml={7} display={{ _: 'none', large: 'block' }}>
                    <Logo variant="business" monochrome />
                </Box>
                <Box my={{ _: 0, large: 4 }}>
                    <img width="100%" height="auto" src={inviteLinkImage} alt="" />
                </Box>
                <Box
                    maxWidth="1400px"
                    mx="auto"
                    display="flex"
                    flexDirection={{ _: 'column-reverse', large: 'row' }}
                    px={{ _: 2, medium: 5, large: 10 }}
                >
                    <Box width={{ _: '100%', large: '60%' }} mr={{ _: 0, large: 8 }} mt={{ _: 5, large: 0 }}>
                        <Headline as="h2" mb={3}>
                            <FormattedMessage id="invitationLink.landingpage.headline" />
                        </Headline>
                        <Text as="p" mb={3}>
                            {formatMessage('invitationLink.landingpage.description').replace(
                                '*businessAccountName*',
                                businessAccountName,
                            )}
                        </Text>
                        <Headline as="h4" mb={3}>
                            <FormattedMessage id="invitationLink.landingpage.subheadline" />
                        </Headline>
                        <Text as="p" mb={3}>
                            <FormattedMessage id="invitationLink.landingpage.paragraph" />
                        </Text>
                        <Headline as="h4">
                            <FormattedMessage id="invitationLink.landingpage.bulletPointHeadline" />
                        </Headline>
                        <UnorderedList>
                            {bulletPoints.map((bulletPoint, index) => {
                                if (bulletPoint.keyword.trim() && bulletPoint.text.trim()) {
                                    return (
                                        <ListItem key={`invitation-link-landingpage-bullet-point-${index}`}>
                                            <Text>
                                                <strong>{bulletPoint.keyword}</strong> - {bulletPoint.text}
                                            </Text>
                                        </ListItem>
                                    );
                                }

                                return null;
                            })}
                        </UnorderedList>
                        <Text as="p" mt={3} mb={5}>
                            <FormattedMessage id="invitationLink.landingpage.bulletPointFooter" />
                        </Text>
                    </Box>
                    <Box width={{ _: '100%', large: '40%' }}>
                        <Card level={200} p={{ _: 3, large: 5 }} mt={{ _: '-50px', medium: '-75px', large: 0 }}>
                            <Logo variant="business" monochrome />
                            {!emailSent ? (
                                <>
                                    <Headline as="h4" mt={4}>
                                        <FormattedMessage id="invitationLink.landingpage.setupBox.inputHeadline" />
                                    </Headline>
                                    <Input
                                        value={workEmail}
                                        onChange={(e) => setWorkEmail(e.target.value)}
                                        label={formatMessage('invitationLink.landingpage.setupBox.inputLabel')}
                                        width={1}
                                        my={3}
                                    />
                                    <Button width={1} disabled={!isValidEmail || loading} onClick={submitWorkEmail}>
                                        {!loading ? (
                                            <FormattedMessage id="invitationLink.landingpage.setupBox.submitButton" />
                                        ) : (
                                            <Box width="100%" display="flex" justifyContent="center">
                                                <InlineSpinner />
                                            </Box>
                                        )}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Box display="flex" alignItems="center" mb={1} mt={4}>
                                        <EnvelopeIcon />
                                        <Headline as="h4" ml={2}>
                                            <FormattedMessage id="invitationLink.landingpage.setupBox.emailSentHeadline" />
                                        </Headline>
                                    </Box>
                                    <Text as="p">
                                        <FormattedMessage id="invitationLink.landingpage.setupBox.emailSentDescription" />
                                    </Text>
                                    <Headline as="h4">{inviteEmailResponse}</Headline>
                                </>
                            )}
                        </Card>
                    </Box>
                </Box>
            </Box>
        );
    }

    return null;
};
