import React, { useState } from 'react';
import styled from 'styled-components';
import { func, string } from 'prop-types';

import { getSemanticValue, Input } from '@freenow/wave';

import plusIconUrl from '../../../images/icons/ic-plus.svg';
import editIconUrl from '../../../images/icons/ic-edit.svg';

const InputPlaceholder = styled.span`
    color: ${getSemanticValue('foreground-neutral-default')};
`;

const StaticInputField = styled.div`
    position: relative;
    cursor: pointer;
    border-radius: 0.25rem;
    border: 0.0625rem solid ${getSemanticValue('border-neutral-default')};
    font-size: 1rem;
    height: 3rem;
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${getSemanticValue('background-page-default')};
    overflow: hidden;
`;

const StyledInput = styled(Input)`
    display: block;
`;

const KEYCODE_ENTER = 13;

export const TextInputSwitch = ({ id, value: initialValue, placeholder, onSubmit }) => {
    const [currentValue, setCurrentValue] = useState(initialValue);
    const [isEditable, setIsEditable] = useState(false);

    if (isEditable) {
        const submitValue = () => {
            if (currentValue !== initialValue) {
                onSubmit(currentValue);
                setIsEditable(false);
            } else {
                setIsEditable(false);
            }
        };

        const onKeyUp = (event) => {
            if (event.key === 'Enter' || event.keyCode === KEYCODE_ENTER) {
                submitValue();
            }
        };

        return (
            <StyledInput
                id={id}
                value={currentValue}
                onChange={(e) => setCurrentValue(e.target.value)}
                onBlur={() => submitValue()}
                autoFocus
                placeholder={placeholder}
                onKeyUp={onKeyUp}
            />
        );
    }

    const iconUrl = currentValue ? editIconUrl : plusIconUrl;

    return (
        <StaticInputField onClick={() => setIsEditable(true)}>
            {currentValue || <InputPlaceholder>{placeholder}</InputPlaceholder>}
            <img src={iconUrl} alt="icon" />
        </StaticInputField>
    );
};

TextInputSwitch.propTypes = {
    id: string.isRequired,
    onSubmit: func.isRequired,
    value: string,
    placeholder: string,
};
