import Fingerprint2 from 'fingerprintjs2';

/*
 * Copied from official Github repo.
 * https://github.com/Valve/fingerprintjs2#get-and-getpromise
 */
export const getFingerprint = async (): Promise<string> => {
    const components = await Fingerprint2.getPromise();
    const values = components.map((component) => component.value);
    return Fingerprint2.x64hash128(values.join(''), 31);
};
