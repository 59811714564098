import styled from 'styled-components';

export const WideLoginContent = styled.div`
    width: 90%;
    max-width: 610px;
    margin: 30px auto 40px auto;

    @media (min-height: 900px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
`;
