import { useState, useEffect, useMemo } from 'react';

export const useMatchMedia = (mediaQuery: string) => {
    const matcher = useMemo(() => {
        const sanitizedQuery = mediaQuery.replace('@media screen and ', ''); // compatibility with wave MediaQueries
        return window.matchMedia(sanitizedQuery);
    }, [mediaQuery]);

    const [matches, setMatches] = useState(matcher.matches);

    useEffect(() => {
        const handler = (e: MediaQueryListEvent) => {
            setMatches(e.matches);
        };
        matcher.addListener(handler);
        return () => matcher.removeListener(handler);
    }, [matcher]);

    return matches;
};
