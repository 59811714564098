import React from 'react';
import styled from 'styled-components';

import { Box, getSemanticValue, Headline, Text } from '@freenow/wave';

const BorderedBox = styled(Box)`
    border-top: 1px solid ${getSemanticValue('border-neutral-default')};
`;

export type FormSectionContent = {
    description: string | React.ReactNode;
    content: string | React.ReactNode;
};

interface Props {
    headline: string;
    content: FormSectionContent[];
    contentMaxWidth?: string;
}

export const FormSection: React.FC<Props> = ({ headline, content, contentMaxWidth = '100%' }) => (
    <>
        <Headline as="h3">{headline}</Headline>
        <BorderedBox display="flex" pt={3} pb={5} mt={2} flexDirection="column">
            {content.map((value, index) => (
                <Box
                    key={`form-section-content-${index}`}
                    display="flex"
                    flexDirection={{ _: 'column', xlarge: 'row' }}
                    mb={content.length > index + 1 ? 3 : 0}
                >
                    <Box
                        minWidth={{ _: '100%', xlarge: '325px' }}
                        width={{ _: '100%', xlarge: '325px' }}
                        mr={{ _: 0, xlarge: 5 }}
                        mb={{ _: 3, xlarge: 0 }}
                    >
                        <Text as="div" fontSize={1} secondary>
                            {value.description}
                        </Text>
                    </Box>
                    <Box display="flex" flexDirection="column" flexGrow={1} maxWidth={contentMaxWidth} minWidth={0}>
                        {value.content}
                    </Box>
                </Box>
            ))}
        </BorderedBox>
    </>
);
