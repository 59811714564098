import styled from 'styled-components';

import { getSemanticValue } from '@freenow/wave';

export const TableRow = styled.div.attrs({ 'data-testid': 'table-row' })`
    border-bottom: 0.0625rem solid ${getSemanticValue('border-neutral-default')};
    height: 4.625rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;
`;
