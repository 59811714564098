import React from 'react';
import styled from 'styled-components';

import { InlineSpinner } from '@freenow/wave';

const CenteredLoadingIndicatorWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const CenteredLoadingIndicator = () => (
    <CenteredLoadingIndicatorWrapper>
        <InlineSpinner />
    </CenteredLoadingIndicatorWrapper>
);
