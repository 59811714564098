export enum ROUTES {
    OAUTH_CALLBACK = '/oauth-callback',
    ROOT = '/',
    LOGIN = '/login',
    SIGN_UP = '/sign-up',
    SIGN_UP_CREATE_ACCOUNT = '/sign-up/create-account',
    SIGN_UP_EXISTING_PASSENGER_ACCOUNT = '/sign-up/existing-passenger-account',
    SIGN_UP_LOGIN = '/sign-up/login',
    SIGN_UP_VERIFY_PHONE_NUMBER = '/sign-up/verify-phone-number',
    SIGN_UP_ADD_PAYMENT_METHOD = '/sign-up/add-payment-method',
    SECRET_SIGN_UP = '/self-service',
    SECRET_SIGN_UP_CREATE_ACCOUNT = '/self-service/create-account',
    SECRET_SIGN_UP_EXISTING_PASSENGER_ACCOUNT = '/self-service/existing-passenger-account',
    SECRET_SIGN_UP_LOGIN = '/self-service/login',
    SECRET_SIGN_UP_VERIFY_PHONE_NUMBER = '/self-service/verify-phone-number',
    SECRET_SIGN_UP_ADD_PAYMENT_METHOD = '/self-service/add-payment-method',
    JOIN_COMPANY = '/join-company',
    JOIN_COMPANY_INVITATION = '/join-company/:companyName/:inviteId/:emailAddress',
    JOIN_COMPANY_INVITATION_LOGIN = '/join-company/:companyName/:inviteId/:emailAddress/login',
    JOIN_COMPANY_INVITATION_REGISTER = '/join-company/:companyName/:inviteId/:emailAddress/register',
    JOIN_COMPANY_SUCCESS = '/join-company/:companyName/success',
    CREDIT_CARD_REDIRECT = '/credit-card-redirect',
    DASHBOARD = '/dashboard',
    TRIPS = '/trips',
    EMPLOYEES = '/employees',
    EMPLOYEES_APPROVED = '/employees/approved',
    EMPLOYEES_INVITED = '/employees/invited',
    EMPLOYEES_GROUPS = '/employees/groups',
    EMPLOYEES_GROUPS_NEW = '/employees/groups/new',
    EMPLOYEES_GROUPS_DETAIL = '/employees/groups/:groupId',
    COST_CENTRES = '/cost-centres',
    STATEMENTS = '/statements',
    ACCOUNT = '/account',
    ACCOUNT_INFORMATION = '/account/information',
    ACCOUNT_SETTINGS = '/account/settings',
    ACCOUNT_PAYMENT = '/account/payment',
    JOB_TICKET_POLICIES = '/job-ticket-policies',
    JOB_TICKET_POLICIES_LIST = '/job-ticket-policies/list',
    CREATE_JOB_TICKET_POLICY = '/job-ticket-policies/new',
    EDIT_JOB_TICKET_POLICY = '/job-ticket-policies/:policyId',
    RIDE_REFERENCES = '/ride-references',
    CONNECTED_INTEGRATIONS = '/integrations',
    AVAILABLE_INTEGRATIONS = '/integrations/list',
    INVITATION_LINK = '/invitation-link',
    INVITATION_LINK_LANDINGPAGE = '/invitation-link/:invitationLinkToken',
    INVITATION_LINK_EXISTING_PASSENGER_ACCOUNT = '/invitation-link/existing-passenger-account',
    REPORTS = '/benefits-card/reports',
    POLICIES = '/policies',
    POLICIES_LIST = '/policies/list',
    CREATE_POLICIES = '/policies/new',
    EDIT_POLICIES = '/policies/:policyId',
    BENEFITS_CARD = '/benefits-card',
    BENEFITS_CARD_REPORTS = '/benefits-card/reports',
    BENEFITS_CARD_TRANSACTIONS = '/benefits-card/transactions',
    BUSINESS_PROFILE_EMAIL_VERIFICATION = '/business-profiles/work-email-verification',
}

export const LOCALE_BA_ID_FALLBACK = '/:locale/:businessAccountId/*';
