import { config } from '@mytaxi/config-store';

import { authorizedAxiosInstance, axiosInstance } from '../../common/api/axios-instance';

export const fetchCountryList = () => {
    return axiosInstance.get(`${config.getItem('apgsUrl')}/v1/self-sign-up-countries`);
};

export const createAccount = (requestBody) => {
    const baseUrl = `${config.getItem('apgsUrl')}/v1/business-account-drafts`;

    return axiosInstance.post(baseUrl, requestBody);
};

export const updateDraft = async (draftId, requestBody) => {
    return authorizedAxiosInstance.patch(
        `${config.getItem('apgsUrl')}/v1/business-account-drafts/${draftId}`,
        requestBody,
    );
};

export const requestPaymentMethodRegistrationUrl = (draftId, countryCode, successUrl, cancelUrl, failureUrl) => {
    const url = `${config.getItem('apgsUrl')}/v1/business-account-drafts/${draftId}/credit-card-registration-url`;
    return authorizedAxiosInstance
        .get(url, {
            params: {
                countryCode,
                successUrl,
                cancelUrl,
                failureUrl,
            },
        })
        .then((res) => res.data);
};

export const getVerificationState = async () => {
    return authorizedAxiosInstance.get(`${config.getItem('apgsUrl')}/v1/passengers/phone-number-validation`);
};

export const triggerVerificationSMS = async () => {
    return authorizedAxiosInstance.post(`${config.getItem('apgsUrl')}/v1/passengers/phone-number-validation`);
};
