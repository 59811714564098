import { AxiosPromise } from 'axios';
import React, { useEffect } from 'react';

import { config } from '@mytaxi/config-store';

import { axiosInstance } from '../api/axios-instance';
import { CenteredLoadingIndicator } from '../components';
import { logAxiosError } from '../helpers/axiosLogger';

interface GtcListResponse {
    gtcs: {
        id: string;
        countryCode: string;
        entityName: string;
        gtcLink: string;
    }[];
}

interface RemoteSettingsResponse {
    remoteSettings: {
        contactEmailAddress: string;
        countryCode: string;
        dataPrivacyLink: string;
        gtcLink: string;
        legalEntityName: string;
        legalNoteLink: string;
        faqLink?: string;
        taxIdRequired: boolean;
        vatIdRequired: boolean;
        invoiceScheduleChangeAllowed: boolean;
        helpCentreLink: string;
    }[];
}

interface RemoteSetting {
    contactEmailAddress: string;
    generalTermsConditions: {
        id: string | undefined;
        link: string | undefined;
        entityName: string | undefined;
        countryCode: string | undefined;
    };
    legalEntityName: string;
    legalNoteLink: string;
    dataPrivacyLink: string;
    faqLink?: string;
    taxIdRequired: boolean;
    vatIdRequired: boolean;
    invoiceScheduleChangeAllowed: boolean;
    helpCentreLink: string;
}

interface IRemoteSettingsContext {
    getSettings: (countryCode: string) => RemoteSetting | undefined;
}

const RemoteSettingsContext = React.createContext<IRemoteSettingsContext>({
    getSettings: () => {
        throw Error('RemoteSettingsContext is not initialized yet.');
    },
});

const RemoteSettingsContextProvider = (props: { children: React.ReactNode }) => {
    const [settings, setSettings] = React.useState<Map<string, RemoteSetting>>();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        const remoteSettingsPromise: AxiosPromise<RemoteSettingsResponse> = axiosInstance.get(
            `${config.getItem('apgsUrl')}/v1/remote-settings`,
        );
        const gtcListPromise: AxiosPromise<GtcListResponse> = axiosInstance.get(`${config.getItem('apgsUrl')}/v1/gtcs`);

        Promise.all([remoteSettingsPromise, gtcListPromise])
            .then(([remoteSettingsResponse, gtcListResponse]) => {
                const settingsMap = new Map<string, RemoteSetting>();

                remoteSettingsResponse.data.remoteSettings.forEach((remoteSetting) => {
                    const gtcIdEntity = gtcListResponse.data.gtcs.find(
                        (it) => it.countryCode.toLowerCase() === remoteSetting.countryCode.toLowerCase(),
                    );

                    settingsMap.set(remoteSetting.countryCode.toLowerCase(), {
                        contactEmailAddress: remoteSetting.contactEmailAddress,
                        dataPrivacyLink: remoteSetting.dataPrivacyLink,
                        generalTermsConditions: {
                            id: gtcIdEntity?.id,
                            link: gtcIdEntity?.gtcLink,
                            entityName: gtcIdEntity?.entityName,
                            countryCode: gtcIdEntity?.countryCode,
                        },
                        legalEntityName: remoteSetting.legalEntityName,
                        legalNoteLink: remoteSetting.legalNoteLink,
                        faqLink: remoteSetting.faqLink,
                        taxIdRequired: remoteSetting.taxIdRequired,
                        vatIdRequired: remoteSetting.vatIdRequired,
                        invoiceScheduleChangeAllowed: remoteSetting.invoiceScheduleChangeAllowed,
                        helpCentreLink: remoteSetting.helpCentreLink,
                    });
                });

                setSettings(settingsMap);
            })
            .catch((apiException) => {
                logAxiosError('Failed to load required settings', apiException);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <CenteredLoadingIndicator />;
    }

    const context: IRemoteSettingsContext = {
        getSettings: (countryCode: string) => {
            if (countryCode && settings) {
                return settings.get(countryCode.toLowerCase());
            }
        },
    };

    return <RemoteSettingsContext.Provider value={context}>{props.children}</RemoteSettingsContext.Provider>;
};

export { RemoteSettingsContextProvider, RemoteSettingsContext };
