import { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface MessageValues {
    [key: string]: string;
}

export function useFormatMessage() {
    const intl = useIntl();

    return useCallback(
        (id: string, values?: MessageValues, defaultMessage?: string) =>
            intl.formatMessage({ id, defaultMessage }, values),
        [intl],
    );
}
