import React from 'react';
import styled from 'styled-components';
import { func, string } from 'prop-types';

import { Input } from '@freenow/wave';

import searchIcon from '../../../images/icons/icon-search.svg';
import { SearchIcon } from './SearchIcon';

const TextFieldWrapper = styled.div`
    position: relative;
`;

const StyledInput = styled(Input)`
    input {
        padding-left: 2rem;
    }
`;

export const TableSearchField = ({ searchQuery, onSearchQueryChange, placeholder, ...rest }) => (
    <TextFieldWrapper>
        <StyledInput
            id="employee-search-field"
            type="search"
            value={searchQuery}
            placeholder={placeholder}
            width="18.75rem"
            size="small"
            onChange={({ target: { value } }) => onSearchQueryChange(value)}
            {...rest}
        />
        <SearchIcon src={searchIcon} alt="search" top="0.5rem" left="0.5rem" />
    </TextFieldWrapper>
);

TableSearchField.propTypes = {
    searchQuery: string,
    placeholder: string,
    onSearchQueryChange: func,
};
