import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { getSemanticValue, Spaces, TabBar, Text } from '@freenow/wave';

export interface NavRoute {
    path: string;
    label: string;
}

interface Props {
    routes: NavRoute[];
    dataTestId?: string;
    disabled?: boolean;
}

const OpaqueText = styled(Text)`
    opacity: 0.5;
    color: ${(props) =>
        props.selected ? getSemanticValue('foreground-focus') : getSemanticValue('foreground-neutral-default')};
`;

const Underline = styled.div`
    height: 0.1875rem;
    margin-top: ${Spaces[1]};
    background-color: ${(props) => (props.selected ? getSemanticValue('border-focus') : 'transparent')};
`;

export const PageNavigation: FC<Props> = ({ routes = [], dataTestId = 'navigation-tabs', disabled = false }) => {
    const history = useHistory();

    return (
        <TabBar data-testid={dataTestId}>
            {routes.map((route, index) => {
                if (disabled) {
                    return (
                        <OpaqueText
                            fontSize={1}
                            fontWeight="semibold"
                            mr={routes.length === index + 1 ? 0 : 3}
                            selected={history.location.pathname === route.path}
                            key={`disabled-pagination-item-${index}`}
                        >
                            {route.label}
                            <Underline selected={history.location.pathname === route.path} />
                        </OpaqueText>
                    );
                }

                return (
                    <TabBar.Link as={NavLink} key={route.path} to={route.path}>
                        {route.label}
                    </TabBar.Link>
                );
            })}
        </TabBar>
    );
};
