import { config } from '@mytaxi/config-store';
import { ExternalPaymentSolution } from '../../common/types/ExternalPaymentSolution';
import { BenefitsType, BusinessAccountDetails } from '../../common/context/BusinessAccountContext';

export const isPaymentTabAvailable = (
    externalPaymentSolution: ExternalPaymentSolution,
    employeeBenefitsBusinessAccount: boolean,
) => {
    const airPlusAccount = externalPaymentSolution && externalPaymentSolution === ExternalPaymentSolution.AIRPLUS;

    return !airPlusAccount && !employeeBenefitsBusinessAccount;
};

export const isPSD2infoEnabled = (country: string) =>
    country && config.getItem('featureFlags.psd2InfoEnabled').includes(country);

export const areStatementsAllowedForCountry = (country: string) =>
    country && !config.getItem('featureFlags.disableStatements').includes(country);

export const areStatementsAvailableForBusinessAccount = (
    businessAccount: BusinessAccountDetails,
    externalPaymentSolution: ExternalPaymentSolution,
) => {
    const businessAccountCountry = businessAccount.countryCode;

    if (
        !areStatementsAllowedForCountry(businessAccountCountry) ||
        businessAccount.benefitsType === BenefitsType.BenefitsCard
    ) {
        return false;
    }

    return externalPaymentSolution && externalPaymentSolution !== ExternalPaymentSolution.AIRPLUS;
};

export const isMultiCardAvailable = (
    businessAccount: BusinessAccountDetails,
    externalPaymentSolution: ExternalPaymentSolution,
) => {
    const { settings, countryCode } = businessAccount;

    if (!settings.multiCreditCardAllowed && config.getItem('featureFlags.disableMultiCard').includes(countryCode)) {
        return false;
    }

    return isPaymentTabAvailable(externalPaymentSolution, businessAccount.employeeBenefitsBusinessAccount);
};

export const areIndividualTripReceiptsEnabledForCountry = (country: string) =>
    country && config.getItem('featureFlags.individualTripReceiptsEnabledCountries').includes(country);

export const isWelcomeDialogEnabledForCountry = (country: string) => {
    const welcomeDialogDisabledCountries = config.getItem('featureFlags.disableWelcomeDialog');

    if (!welcomeDialogDisabledCountries) {
        return true;
    }

    return Array.isArray(welcomeDialogDisabledCountries) && !welcomeDialogDisabledCountries.includes(country);
};

export const isPreferredPaymentMethodSelectorEnabledForCountry = (country: string) => {
    const preferredPaymentMethodSelectorDisabledCountries = config.getItem(
        'featureFlags.disablePreferredPaymentMethodSelector',
    );

    if (!preferredPaymentMethodSelectorDisabledCountries) {
        return true;
    }

    return (
        Array.isArray(preferredPaymentMethodSelectorDisabledCountries) &&
        !preferredPaymentMethodSelectorDisabledCountries.includes(country)
    );
};

export const isMultiMobilityEnabledForCountry = (country: string) => {
    const multiMobilityEnabledCountries = config.getItem('featureFlags.multiMobilityEnabledCountries');

    return Array.isArray(multiMobilityEnabledCountries) && multiMobilityEnabledCountries.includes(country);
};

export const isIntegrationsAvailable = () => config.getItem('featureFlags.integrations');

export const isMonthlyReportsAvailable = () => config.getItem('featureFlags.monthlyReports');

export const isTripsAvailable = (businessAccountDetails: BusinessAccountDetails) =>
    !businessAccountDetails.employeeBenefitsBusinessAccount ||
    businessAccountDetails.benefitsType === BenefitsType.MoBuInvoicing;

export const isTravelPoliciesAvailable = (businessAccountDetails: BusinessAccountDetails) =>
    !businessAccountDetails.employeeBenefitsBusinessAccount ||
    businessAccountDetails.benefitsType !== BenefitsType.NoBenefits;

export const isBenefitsBudgetTypesAvailable = () => config.getItem('featureFlags.benefitsBudgetTypes');
