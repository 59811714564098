import React, { createContext, useEffect, useState, useCallback } from 'react';

import Logger from '@mytaxi/logging';

export const TranslationContext = createContext({});

export interface TranslationContextProviderProps {
    locale: string;
    children: React.ReactNode;
}

export const TranslationContextProvider = ({ locale, children }: TranslationContextProviderProps) => {
    const [translations, setTranslations] = useState({});

    const fetchTranslations = useCallback(() => {
        const localeTranslations = require(`../../../../public/translations/${locale}.json`);
        setTranslations(localeTranslations);
    }, [locale]);

    useEffect(() => {
        Logger.setMetadata('locale', locale);
        Logger.debug(`Changing locale to ${locale}`);
        fetchTranslations();
    }, [locale, fetchTranslations]);

    return <TranslationContext.Provider value={translations}>{children}</TranslationContext.Provider>;
};
