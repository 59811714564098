import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { ArrowLeftIcon, getSemanticValue, Text } from '@freenow/wave';

interface Props {
    onClick: () => void;
}

const BlueSpan = styled.span`
    color: ${getSemanticValue('foreground-accent-default')};
    cursor: pointer;
`;

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
    vertical-align: bottom;
    margin-right: 4px;
`;

export const BackButton = ({ onClick }: Props) => (
    <Text as="p" fontSize={1} fontWeight="semibold" mt={1} mb={2}>
        <BlueSpan onClick={onClick}>
            <StyledArrowLeftIcon color={getSemanticValue('foreground-accent-default')} size={20} />
            <FormattedMessage id="common.back" />
        </BlueSpan>
    </Text>
);
