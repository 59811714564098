import React, { Fragment } from 'react';
import { string } from 'prop-types';
import { Helmet } from 'react-helmet';

import { ErrorPanel } from '@mytaxi/react-error-screens';
import { TranslationContext } from '@mytaxi/dynamic-translations';

import { useFormatMessage } from '../hooks/useFormatMessage';

const WrappedErrorPanelComponent = ({ errorStack }) => {
    const formatMessage = useFormatMessage();
    const headerTitle = formatMessage('errorPanel.header.headerTitle') || 'Oops, sorry this Tool couldn’t load.';
    const headerSubTitle =
        formatMessage('errorPanel.header.headerSubTitle') || 'To load this tool again please refresh the page.';
    const infoTitle = formatMessage('errorPanel.detailedInfo.infoTitle') || 'Show more details';
    const buttonTitle = formatMessage('errorPanel.detailedInfo.buttonTitle') || 'Copy to clipboard';

    return (
        <Fragment>
            <Helmet>
                <title>🤷‍♂️</title>
            </Helmet>
            <ErrorPanel
                title={headerTitle}
                subTitle={headerSubTitle}
                detailsTitle={infoTitle}
                buttonTitle={buttonTitle}
                errorStack={errorStack}
                showMoreInformation={false}
            />
        </Fragment>
    );
};

WrappedErrorPanelComponent.propTypes = {
    errorStack: string,
};

export const WrappedErrorPanel = (
    <TranslationContext.Consumer>
        <WrappedErrorPanelComponent />
    </TranslationContext.Consumer>
);
