import React from 'react';

import { Button, Text, Tooltip, getSemanticValue } from '@freenow/wave';

import { ReactComponent as TrashIcon } from '../../../images/icons/icon-trash.svg';

type Props = {
    children: React.ReactNode;
    disabled?: boolean;
    onClick: () => void;
    tooltip: string;
};

export const DeleteButtonSmall: React.FC<Props> = ({ tooltip, disabled = false, onClick, children }) => (
    <Tooltip content={tooltip}>
        <Button variant="danger" size="small" onClick={onClick} disabled={disabled}>
            <TrashIcon color={getSemanticValue('foreground-on-background-danger')} height={12} width={12} />
            <Text ml={1} fontSize={1}>
                {children}
            </Text>
        </Button>
    </Tooltip>
);
