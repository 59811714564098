import { useContext } from 'react';
import { BusinessAccountContext } from '../context/BusinessAccountContext';

export function useBusinessAccountDetails() {
    const { currentBusinessAccount } = useContext(BusinessAccountContext);

    if (!currentBusinessAccount) {
        throw Error('BusinessAccountContext is not initialized');
    }

    return currentBusinessAccount;
}
