import TagManager from 'react-gtm-module';
import storage from 'local-storage-fallback';

import LOCAL_STORAGE_KEYS from '../../../common/constants/common.localStorageKeys';

export function trackEvent(eventName, additionalInformation) {
    TagManager.dataLayer({
        dataLayer: {
            event: eventName,
            ...additionalInformation,
        },
    });
}

const categories = {
    trips: 'Trips',
    signUp: 'Sign Up',
    employees: 'Employees',
    costCentres: 'Cost Centres',
    joinCompany: 'Join Company',
    crossBorderSettings: 'Cross Border Settings',
    login: 'Login',
};

const actions = {
    downloadTripReceipt: 'Download Receipt',
    downloadTripsOverview: 'Download Trips Overview',
    viewTripDetails: 'View Trip Details',
    completeSignUp: 'Complete Sign Up',
    deleteEmployees: 'Delete Employees',
    inviteEmployees: 'Invite Employees',
    getStarted: 'Get Started',
    saveCard: 'Save Card',
    addCardLater: 'Add Later',
    setUpAccount: 'Set Up Account',
    createAccount: 'Create Account Now',
    connectAccount: 'Connect Account Now',
    resendEmployeeInvitations: 'Resend Employee Invitations',
    deleteEmployeeInvitations: 'Delete Employee Invitations',
    addCostCentres: 'Add Cost Centres',
    deleteCostCentres: 'Delete Cost Centres',
    deleteCostAllCentres: 'Delete All Cost Centres',
    updateCostCentres: 'Update Cost Centres',
    employeeAcceptInvitation: 'Employee accepts Invitation',
    employeeClicksInvitation: 'Employee Clicks Invitation',
    downloadEmployeeList: 'Download Employee List',
    loginError: 'Login Error',
    joinCompanyError: 'Join Company Error',
    updateCrossBorderSetting: 'Update Cross Border Setting',
    receiptBulkDownload: 'Receipt Bulk Download',
};

export const trackTripReceiptDownload = (businessAccountId) => {
    trackEvent(actions.downloadTripReceipt, { category: categories.trips, businessAccountId });
};

export const trackViewTripDetails = () => {
    trackEvent(actions.viewTripDetails, { category: categories.trips });
};

const assembleSignUpUrl = () => {
    let url = new URL(window.location.href);
    url = `${url.origin}${url.pathname}`;
    const params = storage.getItem(LOCAL_STORAGE_KEYS.SIGN_UP_TRACKING_PARAMS);
    if (params) {
        return `${url}${params}`;
    }
    return url;
};

export const trackCompleteSignUp = (businessAccountId) => {
    trackEvent(actions.completeSignUp, {
        category: categories.signUp,
        businessAccountId: businessAccountId.toString(),
        url: assembleSignUpUrl(),
    });
};

export const trackCreateAccount = () => {
    trackEvent(actions.createAccount, { category: categories.signUp, url: assembleSignUpUrl() });
};

export const trackSetUpAccount = () => {
    trackEvent(actions.setUpAccount, { category: categories.signUp, url: assembleSignUpUrl() });
};

export const trackConnectAccount = () => {
    trackEvent(actions.connectAccount, { category: categories.signUp, url: assembleSignUpUrl() });
};

export const trackSaveCard = () => {
    trackEvent(actions.saveCard, { category: categories.signUp, url: assembleSignUpUrl() });
};

export const trackAddCardLater = () => {
    trackEvent(actions.addCardLater, { category: categories.signUp, url: assembleSignUpUrl() });
};

export const trackGetStarted = () => {
    trackEvent(actions.getStarted, { category: categories.signUp, url: assembleSignUpUrl() });
};

export const trackResendEmployeeInvitations = (value) => {
    trackEvent(actions.resendEmployeeInvitations, { category: categories.employees, value });
};

export const trackDeleteEmployeeInvitations = (value) => {
    trackEvent(actions.deleteEmployeeInvitations, { category: categories.employees, value });
};

export const trackInviteMultipleEmployees = (value) => {
    trackEvent(actions.inviteEmployees, { category: categories.employees, label: 'Multiple Employees', value });
};

export const trackInviteSingleEmployee = () => {
    trackEvent(actions.inviteEmployees, { category: categories.employees, label: 'Single Employees' });
};

export const trackDeleteEmployees = (value) => {
    trackEvent(actions.deleteEmployees, { category: categories.employees, value });
};

export const trackAddCostCentres = (value) => {
    trackEvent(actions.addCostCentres, { category: categories.costCentres, value });
};

export const trackDeleteCostCentres = (value) => {
    trackEvent(actions.deleteCostCentres, { category: categories.costCentres, value });
};

export const trackDeleteAllCostCentres = (value) => {
    trackEvent(actions.deleteCostAllCentres, { category: categories.costCentres, value });
};

export const trackUpdateCostCentres = (value) => {
    trackEvent(actions.updateCostCentres, { category: categories.costCentres, value });
};

export const trackDownloadTripsOverview = (type) => {
    trackEvent(actions.downloadTripsOverview, { category: categories.trips, label: type });
};

export const trackEmployeeAcceptInvitation = (inviteId, employeeId) => {
    trackEvent(actions.employeeAcceptInvitation, {
        category: categories.joinCompany,
        inviteId: inviteId.toString(),
        employeeId: employeeId.toString(),
    });
};

export const trackEmployeeClicksInvitation = (inviteId) => {
    trackEvent(actions.employeeClicksInvitation, {
        category: categories.joinCompany,
        inviteId: inviteId.toString(),
    });
};

export const trackCrossBorderSettings = (country, enabled) => {
    trackEvent(actions.updateCrossBorderSetting, {
        category: categories.crossBorderSettings,
        label: enabled ? 'Allow' : 'Decline',
        country,
    });
};

export const trackEmployeeListDownload = () => {
    trackEvent(actions.downloadEmployeeList, { category: categories.employees });
};

export const trackLoginError = (label) => {
    trackEvent(actions.loginError, { category: categories.login, label });
};

export const trackJoinCompanyError = (label) => {
    trackEvent(actions.joinCompanyError, { category: categories.joinCompany, label });
};

export const trackReceiptBulkDownload = (label) => {
    trackEvent(actions.receiptBulkDownload, { category: categories.trips, label });
};
