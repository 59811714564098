import React from 'react';
import { Placement } from '@popperjs/core';
import { Tooltip, Box, InfoCircleIcon, Text, BoxProps } from '@freenow/wave';

interface Props extends BoxProps {
    toolTipContent?: string;
    text: string;
    placement?: Placement;
}

export const TooltipedIconText = ({ toolTipContent, text, placement, ...props }: Props) => (
    <Box {...props} display="flex" alignItems="center">
        <Text as="p" mr={1}>
            {text}
        </Text>
        <Tooltip placement={placement} content={toolTipContent}>
            <Box data-testid={toolTipContent} display="flex" alignItems="center">
                <InfoCircleIcon cursor="pointer" />
            </Box>
        </Tooltip>
    </Box>
);
