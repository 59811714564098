import { useEffect } from 'react';

export function useSyncWithLocalStorage(key: string, value?: string) {
    useEffect(() => {
        if (value) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }, [key, value]);
}
